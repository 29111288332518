import React, { useEffect, useState } from 'react';
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Col, Row, Table, Tag } from "antd";
import { BreadcrumbsAdminIPAddresses } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";
import { GetIPCheckerLink } from '../../components/getPageLinks';

export const IpAddresses = () => {
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);
    const [err, setErr] = useState("");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleFailedAttemptsTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const fetchFailedAttempts = () => {
        setLoadingData(true);
        GetRequestInternalAPI(`/api/v1/security/ip/list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`,
            setLoadingData,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.Records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.result.Total,
                        },
                    });
                },
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchFailedAttempts();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const columnsIPAddresses = [
        {
            title: 'Record created',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <>{record.createdAt ?
                    <TimeFromSeconds seconds={record.createdAt.seconds * 1000} />
                    : <></>}</>
            ),
        },
        {
            title: 'IP',
            dataIndex: 'ipAddr',
            key: 'ipAddr',
            render: (_, record) => (
                <>{record.ipAddr !== "" && record.ipAddr !== undefined ? GetIPCheckerLink(record.ipAddr) : <></>}</>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (_, record) => (
                <>{record.Country !== "" && record.Country !== undefined ? record.Country : <>unknown</>}{record.City !== "" && record.City !== undefined ? <>, {record.City}</> : <>, unknown</>}</>
            ),
        },
        {
            title: 'Blocked',
            dataIndex: 'Blocked',
            key: 'Blocked',
            render: (_, record) => (
                <>{record.isBlocked === true ? <Tag color={'volcano'}>YES</Tag> : <Tag color={'lime'}>NO</Tag>}</>
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'blockReason',
            key: 'blockReason',
        },
    ];

    return (
        <>
            <Helmet>
                <title>IP addresses list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminIPAddresses />
                    </Col>
                </Row>
                <ErrorRow err={err} />
                <Row>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columnsIPAddresses}
                            rowKey={(record) => record.ID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loadingData}
                            size={"small"}
                            onChange={handleFailedAttemptsTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )


}