import { UnlockOutlined, TeamOutlined, SolutionOutlined, UserAddOutlined, ToolOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";

export function BreadcrumbsAdmin() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                {
                    title: (
                        <>
                            <UnlockOutlined />
                            <span>Admin panel</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminIPAddresses() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                {
                    title: (
                        <>
                            <span>IP addresses</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsers() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                {
                    title: (
                        <>
                            <TeamOutlined />
                            <span>Users</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersClickUp() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                {
                    title: (
                        <>
                            <TeamOutlined />
                            <span>Users ClickUp</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersSessions() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                pages["admin_users"],
                {
                    title: (
                        <>
                            <SolutionOutlined />
                            <span>Sessions</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersFailedLogins() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                pages["admin_users"],
                {
                    title: (
                        <>
                            <span>Failed logins</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersSessionsSession(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                pages["admin_users"],
                pages["admin_sessions"],
                {
                    title: (
                        <>
                            <span>{props.id}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersCreate() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                pages["admin_users"],
                {
                    title: (
                        <>
                            <UserAddOutlined />
                            <span>Create user</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsAdminUsersEdit(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["admin"],
                pages["admin_users"],
                {
                    title: (
                        <>
                            <ToolOutlined />
                            <span>{props.id}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}