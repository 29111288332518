import React, { useState } from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router";
import { Avatar, Badge, Menu } from 'antd';
import {
    PoweroffOutlined,
    BarsOutlined,
    FileProtectOutlined,
    BarChartOutlined,
    RadarChartOutlined,
    HomeOutlined,
    AndroidOutlined,
    FallOutlined, ClusterOutlined, BellOutlined
} from '@ant-design/icons'
import { PostRequest } from "../backend/postRequest";
import { getBackendLink } from "../backend/backend"
import { removeToken } from "../backend/userToken";
import {
    CanCreateUsers,
    CanSeeCampaigns, CanSeeLogs,
    CanSeeMoreFlo,
    CanSeeProducts,
    CanSeeSlim4,
    CanSeeStatistic, CanSeeUsers,
    IsAdmin,
    IsDeveloper
} from "../backend/backendRoles";

export function MenuMain(props) {
    let items = []


    if (props.authed !== true) {
        items.push(
            {
                label: (
                    <a href="/login">
                        Login
                    </a>
                ),
                style: { "marginLeft": "auto" },
            },
        )
    } else {
        items.push(
            {
                label: (
                    <a href="/">
                        Home
                    </a>
                ),
                key: '/',
                icon: <HomeOutlined />,
            },
            CanSeeSlim4(props.grants) === true ? MenuSlim4() : null,
            CanSeeMoreFlo(props.grants) === true ? MenuMoreFlo() : null,
            CanSeeProducts(props.grants) === true ? MenuProducts() : null,
            CanSeeCampaigns(props.grants) === true ? MenuCampaignsV2() : null,
            IsAdmin(props.grants) === true ? MenuAdmin(props.grants) : null,
            CanSeeStatistic(props.grants) === true ?
                {
                    label:
                        props.unresolvedEvents > 0
                            ? <Badge count={props.unresolvedEvents} color={"red"} size="small">
                                Statistic
                            </Badge>
                            :
                            "Statistic"
                    ,
                    key: 'stat',
                    icon: <BarChartOutlined />,
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/stat",
                                }}
                            >
                                Statistic
                            </Link>,
                            key: "/stat",
                            icon: <BarChartOutlined />,
                        },
                        {
                            label: props.unresolvedEvents > 0
                                ?
                                <Badge.Ribbon text={props.unresolvedEvents} color={"red"}>
                                    <Link
                                        to={{
                                            pathname: "/stat/events",
                                        }}
                                    >
                                        <BellOutlined /> Events
                                    </Link>
                                </Badge.Ribbon>
                                :
                                <Link
                                    to={{
                                        pathname: "/stat/events",
                                    }}
                                >
                                    <BellOutlined /> Events
                                </Link>,
                            key: "/stat/events",
                        },
                        CanSeeLogs(props.grants) === true ? SubMenuStatisticLogs(props.grants) : null
                    ],
                }
                : null,
            IsDeveloper(props.grants) === true ? MenuDeveloper(props.grants) : null,
        )


        items.push({
            label:
                props.unreadMessages > 0
                    ?
                    <Badge count={props.unreadMessages}><AvatarMenu avatar_link={props.avatar_link} name={props.name} /></Badge>
                    :
                    <Badge count={0}><AvatarMenu avatar_link={props.avatar_link} name={props.name} /></Badge>,
            key: 'profile',
            icon: props.name,
            style: { "marginLeft": "auto" },
            children: [
                {
                    type: 'group',
                    label: 'Profile',
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/profile/messages",
                                }}
                            >
                                {
                                    props.unreadMessages > 0
                                        ?
                                        <Badge.Ribbon text={props.unreadMessages} color="red">
                                            Messages
                                        </Badge.Ribbon>
                                        :
                                        "Messages"
                                }
                            </Link>,
                            key: "/profile/messages",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/profile/" + props.id,
                                }}
                            >
                                My profile
                            </Link>,
                            key: "/profile/" + props.id,
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/profile/edit",
                                }}
                            >
                                Settings
                            </Link>,
                            key: "/profile/edit",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/profile/security",
                                }}
                            >
                                Security
                            </Link>,
                            key: "/profile/security",
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Other',
                    children: [
                        {
                            label: "Logout",
                            key: 'logout',
                            icon: <PoweroffOutlined />,
                            danger: true,
                        },
                    ],
                },
            ],
        })
    }

    const location = useLocation();
    let c = "/"
    if (location.pathname !== "/") {
        c = location.pathname
    }
    const [current, setCurrent] = useState(c);

    const onClick = (e) => {
        if (e.key === "logout") {
            logout()
        } else {
            setCurrent(e.key);
        }
    }

    return (
        <>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
        </>
    )
}

export function logout() {
    PostRequest(getBackendLink() + '/api/v1/sessions/logout', {})
        .then(
            (result) => {
                if (result.success !== true) {
                    alert(result.error)
                }
                removeToken()
                window.location.href = "/"
            },
            (error) => {
                alert(error.message)
                removeToken()
                window.location.href = "/"
            }
        )
}

export function AvatarMenu(props) {
    return (
        <>
            <Avatar shape="square" style={{ borderRadius: 0 }} src={props.avatar_link}>{props.name}</Avatar>
        </>
    )
}

function MenuCampaignsV2() {
    return (
        {
            label:
                "Campaigns v2"
            ,
            key: 'campaignsv2',
            icon: <FallOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/campaigns/v2",
                        }}
                    >
                        Campaigns overview
                    </Link>,
                    key: "/campaigns/v2",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/campaigns/v2/create",
                        }}
                    >
                        Create campaign
                    </Link>,
                    key: "/campaigns/v2/create",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/campaigns/v2/logs",
                        }}
                    >
                        Action logs
                    </Link>,
                    key: "/campaigns/v2/logs",
                },
            ],
        }
    )
}

function MenuSlim4() {
    return (
        {
            label:
                "Slim4"
            ,
            key: 'slim4',
            icon: <RadarChartOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/slim4",
                        }}
                    >
                        Integration overview
                    </Link>,
                    key: "/slim4",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/slim4/cache_product",
                        }}
                    >
                        Product cache
                    </Link>,
                    key: "/slim4/cache_product",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/slim4/cache_order",
                        }}
                    >
                        Order cache
                    </Link>,
                    key: "/slim4/cache_order",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/slim4/cache_order_historical",
                        }}
                    >
                        Order historical cache
                    </Link>,
                    key: "/slim4/cache_order_historical",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/slim4/cache_transaction",
                        }}
                    >
                        Transaction cache
                    </Link>,
                    key: "/slim4/cache_transaction",
                },
            ],
        }
    )
}

function MenuProducts() {
    return (
        {
            label:
                "Products"
            ,
            key: 'products',
            icon: <BarsOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/products",
                        }}
                    >
                        Products overview
                    </Link>,
                    key: "/products",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/products/list",
                        }}
                    >
                        Product list
                    </Link>,
                    key: "/products/list",
                },
                {
                    type: 'group',
                    label: 'Brands',
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/brands/list",
                                }}
                            >
                                All brands
                            </Link>,
                            key: "/brands/list",
                        },
                    ],
                },
                {
                    label: <Link
                        to={{
                            pathname: "/categories/list",
                        }}
                    >
                        Categories
                    </Link>,
                    key: "/categories/list",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/countries/list",
                        }}
                    >
                        Countries
                    </Link>,
                    key: "/countries/list",
                },
                {
                    type: 'group',
                    label: 'Prices',
                    children: [
                        {
                            label: "Prices",
                            key: "Prices",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/products/prices",
                                        }}
                                    >
                                        Overview
                                    </Link>,
                                    key: "/products/prices",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/products/prices/dynamic",
                                        }}
                                    >
                                        Dynamic
                                    </Link>,
                                    key: "/products/prices/dynamic",
                                }
                            ],
                        },
                    ],
                },
            ],
        }
    )
}

function MenuMoreFlo() {
    return (
        {
            label:
                "MoreFlo"
            ,
            key: 'moreflo',
            icon: <RadarChartOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/moreflo",
                        }}
                    >
                        Integration overview
                    </Link>,
                    key: "/moreflo",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/moreflo/history",
                        }}
                    >
                        History
                    </Link>,
                    key: "/moreflo/history",
                },
            ],
        }
    )
}

function MenuAdmin(grants) {
    return (
        {
            label:
                "Admin",
            key: 'admin',
            icon: <FileProtectOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/admin",
                        }}
                    >
                        Admin panel
                    </Link>,
                    key: "/admin",
                },
                {
                    type: 'group',
                    label: 'Security',
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/admin/ip_addresses/list",
                                }}
                            >
                                IP addresses
                            </Link>,
                            key: "/admin/ip_addresses/list",
                        },

                    ],
                },
                CanSeeUsers(grants) === true ? SubMenuAdminUsers(grants) : null
            ],
        }
    )
}


function SubMenuAdminUsers(grants) {
    return (
        {
            type: 'group',
            label: 'Users',
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/admin/failed_logins",
                        }}
                    >
                        Failed login attempts
                    </Link>,
                    key: "/admin/failed_logins",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/admin/sessions",
                        }}
                    >
                        Sessions
                    </Link>,
                    key: "/admin/sessions",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/admin/users",
                        }}
                    >
                        User list
                    </Link>,
                    key: "/admin/users",
                },
                {
                    label: <Link
                        to={{
                            pathname: "/admin/users/click-up",
                        }}
                    >
                        User list ClickUp
                    </Link>,
                    key: "/admin/users/click-up",
                },
                CanCreateUsers(grants) === true ? {
                    label: <Link
                        to={{
                            pathname: "/admin/users/create",
                        }}
                    >
                        Create user
                    </Link>,
                    key: "/admin/users/create",
                } : null

            ],
        }
    )
}

function SubMenuStatisticLogs(grants) {
    return (
        {
            type: 'group',
            label: 'Logs',
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/stat/logs",
                        }}
                    >
                        All logs
                    </Link>,
                    key: "/stat/logs",
                },
                IsAdmin(grants) === true ? {
                    label: <Link
                        to={{
                            pathname: "/stat/request_logs",
                        }}
                    >
                        Request logs
                    </Link>,
                    key: "/stat/request_logs",
                    icon: <ClusterOutlined />,
                } : null,
            ],
        }
    )
}

function MenuDeveloper(grants) {
    return (
        {
            label:
                "Developer area"
            ,
            key: 'developer',
            icon: <AndroidOutlined />,
            children: [
                {
                    label: <Link
                        to={{
                            pathname: "/developer",
                        }}
                    >
                        Overview
                    </Link>,
                    key: "/developer",
                },
                {
                    label: "Services",
                    key: "/developer/services",
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/runs",
                                }}
                            >
                                Application runs
                            </Link>,
                            key: "/developer/services/runs",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/auth_service",
                                }}
                            >
                                Auth
                            </Link>,
                            key: "/developer/services/auth_service",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/logger_service",
                                }}
                            >
                                Logger
                            </Link>,
                            key: "/developer/services/logger_service",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/notify_service",
                                }}
                            >
                                Notify
                            </Link>,
                            key: "/developer/services/notify_service",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/products_service",
                                }}
                            >
                                Products
                            </Link>,
                            key: "/developer/services/products_service",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/eco_service",
                                }}
                            >
                                Ecosystem
                            </Link>,
                            key: "/developer/services/eco_service",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/services/bizzkit-product-feed",
                                }}
                            >
                                Bizzkit product feed
                            </Link>,
                            key: "/developer/services/bizzkit-product-feed",
                        },
                    ]
                },
                {
                    label: "Access tokens",
                    key: "/developer/access-tokens",
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/access-tokens/list",
                                }}
                            >
                                List
                            </Link>,
                            key: "/developer/access-tokens/list",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/access-tokens/add",
                                }}
                            >
                                Add
                            </Link>,
                            key: "/developer/access-tokens/add",
                        },
                    ]
                },
                {
                    label: <Link
                        to={{
                            pathname: "/developer/endpoints",
                        }}
                    >
                        Endpoints
                    </Link>,
                    key: "/developer/endpoints",
                },
                {
                    label: "Costs",
                    key: "costs",
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/costs",
                                }}
                            >
                                Costs
                            </Link>,
                            key: "/developer/costs",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/costs/clouds",
                                }}
                            >
                                Clouds
                            </Link>,
                            key: "/developer/costs/clouds",
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Health Monitor',
                    children: [
                        {
                            label: "Tables",
                            key: "/developer/healthmonitor/tables",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/healthmonitor/tablesizes",
                                        }}
                                    >
                                        Tables sizes
                                    </Link>,
                                    key: "/developer/healthmonitor/tablesizes",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/healthmonitor/indexes",
                                        }}
                                    >
                                        Indexes
                                    </Link>,
                                    key: "/developer/healthmonitor/indexes",
                                }
                            ],
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Integrations',
                    children: [
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/integrations",
                                }}
                            >
                                Overview
                            </Link>,
                            key: "/developer/integrations",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/integrations/status",
                                }}
                            >
                                Status
                            </Link>,
                            key: "dev_int_status",
                        },
                        {
                            label: <Link
                                to={{
                                    pathname: "/developer/integrations/api-keys",
                                }}
                            >
                                API keys
                            </Link>,
                            key: "dev_apikeys",
                        },
                        {
                            label: "Slim4",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/integrations/slim4",
                                        }}
                                    >
                                        Slim4
                                    </Link>,
                                    key: "/developer/integrations/slim4",
                                },
                            ],
                            key: "dev_slim4"
                        },
                        {
                            label: "MoreFlo",
                            key: "/developer/integrations/moreflo",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/integrations/moreflo",
                                        }}
                                    >
                                        MoreFlo
                                    </Link>,
                                    key: "/developer/moreflo",
                                },
                            ],
                        },
                    ],
                },
                CanSeeProducts(grants) === true ? {
                    type: 'group',
                    label: 'Products',
                    children: [
                        {
                            label:
                                "Feeds",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/feeds",
                                        }}
                                    >
                                        Feeds
                                    </Link>,
                                    key: "/developer/products/feeds",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/feeds/v1",
                                        }}
                                    >
                                        Feeds V1
                                    </Link>,
                                    key: "/developer/products/feeds/v1",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/feeds/v2",
                                        }}
                                    >
                                        Feeds V2
                                    </Link>,
                                    key: "/developer/products/feeds/v2",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/feeds/v2/logs",
                                        }}
                                    >
                                        Feeds V2 Logs
                                    </Link>,
                                    key: "/developer/products/feeds/v2/logs",
                                },
                            ],
                        },
                        {
                            label: "Replicators",
                            children: [
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/replicators",
                                        }}
                                    >
                                        Replicators
                                    </Link>,
                                    key: "/developer/products/replicators",
                                },
                                {
                                    label: <Link
                                        to={{
                                            pathname: "/developer/products/replicators/v1",
                                        }}
                                    >
                                        Replicator V1
                                    </Link>,
                                    key: "/developer/products/replicators/v1",
                                },
                            ]
                        },
                    ],
                } : null,
            ],
        }
    )
}