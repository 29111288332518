import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    Select,
    Space,
    Input, Alert, Typography, Tour
} from 'antd';
import dayjs from 'dayjs';
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../../components/backend/backend";
import { ErrorRow } from "../../../components/errorBlock";
import { NotificationError, NotificationSuccess } from "../../../components/notifications";
import {
    BreadcrumbsDeveloperAccessTokenAdd,
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import {
    FontColorsOutlined,
    QuestionCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import {
    GetGrantsSelectList, IsGrantsAdmin
} from "../../../components/backend/backendRoles";
import { PostRequest } from "../../../components/backend/postRequest";
import { DatePicker } from "antd";
import { TimeFromString } from "../../../components/dateAndTime";
import { Helmet } from "react-helmet";


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};

export const DeveloperAccessTokenAdd = (props) => {
    const [openTour, setOpenTour] = useState(false);

    const tourSteps = [
        {
            title: 'Create token',
            description: 'On this page you can create tokens for specific users',
        },
        {
            title: 'User',
            description: 'Pick the user - all actions made with token will be trated as if this user has made them',
        },
        {
            title: 'Comment',
            description: 'Leave comment to determine specific token in the list later',
        },
        {
            title: 'Expiration date',
            description: 'Set exact date & time when the token will be expired',
        },
        {
            title: 'Grants',
            description: 'This token will have grants you add or, in case list is empty, default grants for selected user. ' +
                'Important: you can add custom grant, that does not exist in system, just by typing the value and pressing ' +
                'Enter. But only grants that your account already has can be added to other users by you',
        },
    ];

    const [newToken, setNewToken] = useState("");
    const [expiresAt, setExpiresAt] = useState("");
    const [loading, setLoading] = useState(false);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [usersErr, setUserErr] = useState("");
    const fetchUserList = () => {
        setLoadingUsers(true);
        GetRequest(getBackendLink() + `/api/v1/users/admin/get-list?page=1&size=500`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setUserErr(result.error)
                    } else {
                        setUserErr("")

                        let newUsers = [
                            { ID: "", Name: " " }
                        ]

                        result.result.Records.map((x) => (
                            newUsers.push({
                                ID: x.ID,
                                Name: <>{x.FirstName} {x.LastName}</>,
                            })
                        ))
                        setUsers(newUsers)
                    }
                    setLoadingUsers(false);
                },
                (error) => {
                    setLoadingUsers(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchUserList();
        // eslint-disable-next-line
    }, []);

    const onFinish = (values) => {
        setLoading(true)
        PostRequest(getBackendLink() + `/api/v1/security/access_token/new`, {
            OwnerID: values.OwnerID,
            Comment: values.Comment,
            Grants: values.Grants,
            ExpiresAt: values.ExpiresAt["$d"],
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        NotificationSuccess('Created!', <>New token has been created</>)
                        setNewToken(result.result.Token)
                        setExpiresAt(result.result.ExpiresAt)
                    }

                    setLoading(false)
                },
                (error) => {
                    NotificationError('Error', error.message)

                    setLoading(false)
                }
            )
    }

    return (
        <>
            <Helmet>
                <title>Create access token || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperAccessTokenAdd />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={usersErr} />
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpenTour(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                About this page <QuestionCircleOutlined />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row className="container-1700">
                    <Col span={24} offset={0}>
                        <Form
                            name="create"
                            {...formItemLayout}
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            style={{
                                maxWidth: "100%",
                            }}
                        >
                            <Form.Item
                                loading={loading}
                                name="OwnerID"
                                label={<><UserOutlined className="site-form-item-icon" />Token owner </>}
                                rules={[{ required: true, message: 'Owner must not be empty' }]}
                            >
                                <Select
                                    loading={loadingUsers}
                                    options={users}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="Comment"
                                label="Comment"
                            >
                                <Input prefix={<FontColorsOutlined className="site-form-item-icon" />} />
                            </Form.Item>
                            <Form.Item
                                name="ExpiresAt"
                                label="Expires on"
                                rules={[{ required: true, message: 'Expiration time must be set' }]}
                            >
                                <DatePicker disabledDate={disabledDate} showTime />
                            </Form.Item>

                            <Alert message="
                            Leave Grants empty to create token with default user grants.
                            To add custom grant, type value and press enter - this grant will be created only for this token.
                            " type="info" style={{ margin: "1em 0 1em 0" }} />
                            <Alert message="
                            Some grants require Administrator or GrantAdmin roles. You can add only grants you already have on your profile.
                            " type="warning" style={{ margin: "1em 0 1em 0" }} />

                            <Form.Item
                                loading={loading}
                                name="Grants"
                                label="Grants"
                            >
                                <Select
                                    disabled={!IsGrantsAdmin(props.grants)}
                                    mode="tags"
                                    options={GetGrantsSelectList(props.grants)}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                    className="login-form-button">
                                    Create token
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Row className={"bottom-1em top-1em"}>
                    <Col xs={24} xl={24}>
                        {newToken ? <Row style={{ "marginTop": "1em" }}>
                            <Col xs={24} xl={24}>
                                <Alert
                                    message={<strong>New token</strong>}
                                    description={<>{<Typography.Text copyable={{ text: newToken }}> {newToken}</Typography.Text>}<br /> <strong>Expires at</strong>: <TimeFromString time={expiresAt} /></>}
                                    type="success"
                                />
                            </Col>
                        </Row> : <></>}
                    </Col>
                </Row>
            </div>

            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
        </>
    );
};
