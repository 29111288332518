import React, { useEffect, useState, useRef } from 'react';
import { Col, Space, Row, Table, Form, Tour, Button, Select, Input, Tag, Typography } from "antd";
import { CaretRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { MoreFloGroups } from "../../components/backend/backend";
import { BreadcrumbsMorefloHistory } from "../../components/breadcrumbs/breadcrumbsMoreflo";
import { Helmet } from "react-helmet";
import { ProductLinkInline } from '../../components/product';
import { TimeFromSecondsSafe } from '../../components/dateAndTime';

const { Paragraph } = Typography

export function MorefloPipelineHistory() {
    const refPickRun = useRef(null);
    const refDownload = useRef(null);
    const refGetData = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Pick run',
            description: 'You can get data for all runs or pick specific one from the list.',
            target: () => refPickRun.current,
        },
        {
            title: 'Download CSV',
            description: <>You can download in CSV, but only for one specific run at once. It will take some time. Pick any record in dropdown and the button will become active. You <strong>don`t</strong> need to get data in browser before that: simply pick a record and press download.</>,
            target: () => refDownload.current,
        },
        {
            title: 'Get data',
            description: 'Press "Get history" to get product data records on browser tab.',
            target: () => refGetData.current,
        },
    ];

    const [form] = Form.useForm();
    const [lastBatches, setLatsBatches] = useState([])
    const batchValue = Form.useWatch('batch', form);
    const [batchSelectedValue, setBatchSelectedValue] = useState('')
    const skuValue = Form.useWatch('sku', form);

    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const getBatchID = () => {
        let batchIDLocal = ""
        if (batchValue !== undefined && batchValue !== null) {
            batchIDLocal = batchValue
        }
        if (batchSelectedValue !== "" && batchSelectedValue !== undefined) {
            batchIDLocal = batchSelectedValue
        }

        return batchIDLocal
    }

    const getSKU = () => {
        let skuLocal = ""
        if (skuValue !== undefined && skuValue !== null && skuValue !== "") {
            skuLocal = skuValue
        }

        return skuLocal
    }

    const fetchData = () => {
        GetRequestInternalAPI(
            `/api/v1/integrations/more_flo/get_product_from_cache?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&batch_id=${getBatchID()}&sku=${getSKU()}`,
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    let newLastRuns = [
                        { ID: "", Name: "all" }
                    ]

                    result.LastRuns.map((x) => (
                        newLastRuns.push({
                            ID: x.BatchNumber,
                            Name: <>{x.BatchNumber} (<TimeFromSecondsSafe format="YYYY.MM.DD HH:mm:ss" time={x.BatchTime} />)</>,
                        })
                    ))
                    setLatsBatches(newLastRuns)

                    const parsedData = result.Records.map(record => ({
                        ...record,
                        ...JSON.parse(record.ItemData)
                    }));
                    setData(parsedData);

                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.Total,
                        },
                    });
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    return (
        <>
            <Helmet>
                <title>MoreFlo history || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsMorefloHistory />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refDownload}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                Take a Tour <QuestionCircleOutlined />
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"History"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refPickRun} >
                        <Form form={form} layout="vertical" autoComplete="off">
                            <Form.Item
                                name={`Batch ID`}
                                label={`Last runs`}
                            >
                                <Select
                                    onChange={(value) => { setBatchSelectedValue(value) }}
                                    options={lastBatches}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                    loading={loading}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item name="sku" label="Item SKU">
                                <Input />
                            </Form.Item>
                            <Button type="primary" onClick={fetchData} ref={refGetData}>
                                Get history <CaretRightOutlined />
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={ColumnsMoreFloHistory}
                            rowKey={(record) => record.id}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            scroll={{
                                y: 500,
                                x: 4000,
                            }}
                        />
                    </Col>
                </Row>
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    )
}


const ColumnsMoreFloHistory = [
    {
        title: 'Item SKU',
        dataIndex: 'ArticleNumber',
        key: 'ArticleNumber',
        fixed: 'left',
        render: (_, record) => (
            <>
                <ProductLinkInline sku={record.SKU} />
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'BatchNumber',
        key: 'BatchNumber',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.BatchNumber }}>{record.BatchNumber}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'BatchTime',
        key: 'BatchTime',
        render: (_, record) => (
            <><TimeFromSecondsSafe format="YYYY.MM.DD HH:mm:ss" time={record.BatchTime} /></>
        ),
    },
    {
        title: 'Article Name',
        dataIndex: 'ArticleName',
        key: 'ArticleName',
    },
    {
        title: 'Updated',
        dataIndex: 'Updated',
        key: 'Updated',
        render: (_, record) => (
            <><TimeFromSecondsSafe format="YYYY.MM.DD HH:mm:ss" time={record.UpdatedAt} /></>
        ),
    },
    {
        title: 'Barcodes',
        dataIndex: 'Barcodes',
        key: 'Barcodes',
        render: (_, record) => (
            <>{record.Barcode + "," + JSON.stringify(record.BarcodeAlternatives)}</>
        ),
    },
    {
        title: 'Retail Price',
        dataIndex: 'RetailPrice',
        key: 'RetailPrice',
    },
    {
        title: 'Is Active',
        dataIndex: 'IsActive',
        key: 'IsActive',
        render: (_, record) => (
            <>{record.IsActive ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Group ID',
        dataIndex: 'GroupId',
        key: 'GroupId',
        render: (_, record) => (
            <>{MoreFloGroups[record.GroupId]}</>
        ),
    },
    {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key: 'Quantity',
    },
    {
        title: 'Quantity Updated',
        dataIndex: 'QuantityUpdatedTime',
        key: 'QuantityUpdatedTime',
    },
    {
        title: 'Account Code ID',
        dataIndex: 'AccountCodeId',
        key: 'AccountCodeId',
    },
    {
        title: 'Article Info Updated',
        dataIndex: 'ArticleInfoUpdateTime',
        key: 'ArticleInfoUpdateTime',
    },
    {
        title: 'Created',
        dataIndex: 'Created',
        key: 'Created',
        render: (_, record) => (
            <>{JSON.stringify(record.CreatedAt.Time)}</>
        ),
    },
    {
        title: 'Input Price',
        dataIndex: 'InputPrice',
        key: 'InputPrice',
    },
    {
        title: 'Recommended Price',
        dataIndex: 'RecommendedPrice',
        key: 'RecommendedPrice',
    },
    {
        title: 'Unit',
        dataIndex: 'Unit',
        key: 'Unit',
    },
    {
        title: 'Is Bookable',
        dataIndex: 'IsBookable',
        key: 'IsBookable',
        render: (_, record) => (
            <>{record.IsBookable ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Open Article',
        dataIndex: 'IsOpenArticle',
        key: 'IsOpenArticle',
        render: (_, record) => (
            <>{record.IsOpenArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Quick Button',
        dataIndex: 'IsQuickButton',
        key: 'IsQuickButton',
        render: (_, record) => (
            <>{record.IsQuickButton ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Stock Item',
        dataIndex: 'IsStockItem',
        key: 'IsStockItem',
        render: (_, record) => (
            <>{record.IsStockItem ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Webshop Article',
        dataIndex: 'IsWebshopArticle',
        key: 'IsWebshopArticle',
        render: (_, record) => (
            <>{record.IsWebshopArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
];