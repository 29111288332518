import { Button, Col, Row, Space, Table, Tag } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import React from 'react';
import { useEffect, useState } from "react";
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { NotificationError } from "../../../components/notifications";
import { ErrorRow } from "../../../components/errorBlock";
import { Spinner } from "../../../components/spinners";
import {
    BreadcrumbsDeveloperProductsFeedsV2
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { Helmet } from "react-helmet";
import { Typography } from "antd";

const { Paragraph } = Typography

export const DeveloperProductFeedsV2 = () => {
    const [err, setErr] = useState("");
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/feeds/v2`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.records);
                        setTotal(result.result.total)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Dev: product feeds v2 || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperProductsFeedsV2 />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <p>{loading ? <><Spinner /></> : <>Total feeds {total}.</>}</p>
                            <Button type="primary">
                                <FileTextOutlined /> <a
                                    href={`https://app.clickup.com/4575209/v/dc/4bkz9-15921/4bkz9-8041`} target={"_blank"}
                                    rel="noreferrer">Documentation</a>
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"Products"} />
            </div>
            <div className="container-1800" style={{ "marginTop": "1em" }}>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            pagination={false}
                            columns={columns}
                            loading={loading}
                            dataSource={data}
                            size={"small"}
                            scroll={{
                                y: 2000,
                            }}

                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

const columns = [
    {
        title: 'Feed',
        dataIndex: 'Feed',
        key: 'Feed',
    },
    {
        title: 'Market',
        dataIndex: 'Market',
        key: 'Market',
        render: (_, record) => (
            record.Market === "ALL" ? <Tag color={'volcano'}>{record.Market}</Tag> : record.Market
        ),
    },
    {
        title: 'Format',
        dataIndex: 'Format',
        key: 'Format',
        render: (_, record) => (
            record.Format === "CSV" ? <Tag color={'lime'}>{record.Format}</Tag> :
                <Tag color={'cyan'}>{record.Format}</Tag>
        ),
    },
    {
        title: 'Production link',
        dataIndex: 'LinkToProduction',
        key: 'LinkToProduction',
        render: (_, record) => (
            <Paragraph copyable={{ text: record.LinkToProduction }}><a href={record.LinkToProduction} target={"_blank"} rel={"noreferrer"}>{record.LinkToProduction}</a></Paragraph>
        ),
    },
    {
        title: 'Developing link',
        dataIndex: 'LinkToDev',
        key: 'LinkToDev',
        render: (_, record) => (
            <Paragraph copyable={{ text: record.LinkToDev }}><a href={record.LinkToDev} target={"_blank"} rel={"noreferrer"}>{record.LinkToDev}</a></Paragraph>
        ),
    },
    {
        title: 'Stage link',
        dataIndex: 'LinkToStaging',
        key: 'LinkToStaging',
        render: (_, record) => (
            <Paragraph copyable={{ text: record.LinkToStaging }}><a href={record.LinkToStaging} target={"_blank"} rel={"noreferrer"}>{record.LinkToStaging}</a></Paragraph>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'Comment',
        key: 'Comment',
    },
];