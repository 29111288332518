import React, { useState, useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Spin, Checkbox, Form, Input, Alert, Col, Row, Image } from 'antd';
import Cookies from 'universal-cookie';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { ParseUserToken } from "../components/backend/parseUserToken";
import { PostRequest } from "../components/backend/postRequest";
import {
    errorTranslation,
    getBackendLink,
    getCookieDomain,
    getMicrosoftClientID,
    getMicrosoftRediredURI
} from "../components/backend/backend";
import { PublicClientApplication } from "@azure/msal-browser";
import { Helmet } from "react-helmet";

const msalConfig = {
    auth: {
        clientId: getMicrosoftClientID(),
        redirectUri: getMicrosoftRediredURI()
    }
};

const errorMessages = {
    not_found: "Wrong login or password",
    incorrect_credentials: "Wrong login or password",
    login_not_allowed: "Login method not allowed or user blocked",
    ip_blocked: "IP address blocked"
};

async function InitMS() {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return msalInstance;
}

export const Login = () => {
    const [hasError, setHasError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState("");
    const [msalInstance, setMsalInstance] = useState(new PublicClientApplication(msalConfig));
    const [form] = Form.useForm();
    const alienValue = Form.useWatch('ualien', form);

    useEffect(() => {
        InitMS()
            .then(setMsalInstance)
            .catch(err => console.error('Microsoft Error:', err));
    }, []);

    const sendCredentials = async (login = "", password = "", TokenBytes, alien = false, UserProvider = "internal") => {
        setIsFetching(true);
        try {
            const result = await PostRequest(getBackendLink() + '/api/v1/sessions/login', {
                login,
                password,
                TokenBytes,
                alien,
                BrowserName: browserName,
                BrowserVersion: browserVersion,
                OS: osName,
                OSVersion: osVersion,
                UserProvider
            });

            if (!result.success) {
                setHasError(true);
                setError(errorMessages[result.error] || result.error);
            } else {
                const cookies = new Cookies();
                cookies.set('userToken', result.token, {
                    domain: getCookieDomain(),
                    path: '/',
                    sameSite: 'none',
                    secure: true
                });
                const parsed = ParseUserToken();
                window.location.href = `/profile/${parsed.ID}`;
            }
        } catch (error) {
            setHasError(true);
            setError(error.message);
        } finally {
            setIsFetching(false);
        }
    };

    const onFinish = values => {
        sendCredentials(values.username, values.password, "", values.ualien, "internal");
    };

    return (
        <>
            <Helmet>
                <title>Login || Bangerhead Admin</title>
            </Helmet>
            {hasError && (
                <Row style={{ marginTop: "1em" }}>
                    <Col span={8} offset={8}>
                        <Alert
                            message="Error"
                            description={errorTranslation(error)}
                            type="error"
                        />
                    </Col>
                </Row>
            )}
            <div className="container-1700" style={{ padding: '2em', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
                <Row justify="center" align="middle">
                    <Col xs={24} sm={16} md={12} lg={8} xl={6}>
                        <div style={{ textAlign: 'center', marginBottom: '1em' }}>
                            <Image
                                width={200}
                                preview={false}
                                src="/logo_transparent_long.png"
                            />
                        </div>
                        <Form
                            form={form}
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            style={{ backgroundColor: 'white', padding: '2em', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
                        >
                            <Spin spinning={isFetching}>
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Login can not be empty' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Password can not be empty' }]}
                                >
                                    <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item style={{ float: "left" }} name="ualien" valuePropName="checked" noStyle>
                                        <Checkbox>NOT my device</Checkbox>
                                    </Form.Item>
                                    <a style={{ float: "right" }} className="login-form-forgot" href="/restore_password">
                                        Restore password
                                    </a>
                                </Form.Item>
                                <Form.Item>
                                    <Button style={{ width: "100%", backgroundColor: '#1890ff', borderColor: '#1890ff' }} type="primary" htmlType="submit" className="login-form-button">
                                        Login
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <img
                                        alt=""
                                        src="https://learn.microsoft.com/en-us/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_signin_light.svg"
                                        onClick={() => {
                                            msalInstance.loginPopup({ scopes: ["email"] })
                                                .then(result => {
                                                    sendCredentials(result.account.username, "-", result.idToken, alienValue, "microsoft");
                                                })
                                                .catch(err => {
                                                    setHasError(true);
                                                    setError(err.message);
                                                });
                                        }}
                                        style={{ cursor: "pointer", width: '100%', marginTop: '1em' }}
                                    />
                                </Form.Item>
                            </Spin>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    );
};