import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { Table } from 'antd';
import { Avatar } from 'antd';
import { GetRequest } from "../../components/backend/getRequest"
import { ErrorBlock } from "../../components/errorBlock"
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend"
import { NotificationError } from "../../components/notifications";
import { BreadcrumbsAdminUsersClickUp } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";

export const AdminUsersListClickUp = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/users/admin/get-list-clickup`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: 'avatar',
            render: (_, record) => (
                <Avatar shape="square" src={record.Avatar}></Avatar>
            ),
        },
        {
            title: 'Username',
            dataIndex: 'Username',
            key: 'Username',
            render: (_, record) => (
                <>{record.Username} ({record.Initials})</>
            ),
            sorter: (a, b) => a.Username - b.Username,
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            render: (_, record) => (
                !record.Email
                    ?
                    <></>
                    :
                    <Typography.Text
                        copyable={{ text: record.Email }}
                        style={{ margin: 0 }}>
                        {record.Email}
                    </Typography.Text>

            ),
        },
        {
            title: 'Joined',
            dataIndex: 'Joined',
            key: 'Joined',
            render: (_, record) => (
                <>{record.DateJoined > 0 ?

                    <TimeFromSeconds seconds={Math.floor(record.DateJoined)} />
                    : <></>}</>
            ),
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
        },
    ];

    return (
        <>
            <Helmet>
                <title>ClickUp User list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersClickUp />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.ID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
