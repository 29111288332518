import React from 'react';
import { Col, Row } from "antd";
import { BreadcrumbsSlim4 } from "../../components/breadcrumbs/breadcrumbsSlim4";
import { Helmet } from "react-helmet";

export function Slim4StatusPage() {
    return (
        <>
            <Helmet>
                <title>Slim4 || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsSlim4 />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                    </Col>
                </Row>
            </div>
        </>
    )
}
