import React from "react";
import { Col, Row } from "antd";
import {
    BreadcrumbsDeveloperIntegrations
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";

export const DeveloperIntegrations = () => {
    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperIntegrations />
                    </Col>
                </Row>
            </div>
        </>
    )
}