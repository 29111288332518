import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";
import React from "react";

export function BreadcrumbsSlim4() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                {
                    title: (
                        <>
                            <span>Slim4</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsSlim4CacheProduct() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["slim4"],
                {
                    title: (
                        <>
                            <span>Cache product</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsSlim4CacheOrder() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["slim4"],
                {
                    title: (
                        <>
                            <span>Cache order</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsSlim4CacheOrderHistorical() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["slim4"],
                {
                    title: (
                        <>
                            <span>Cache order historical</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsSlim4CacheTransaction() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["slim4"],
                {
                    title: (
                        <>
                            <span>Cache transaction</span>
                        </>
                    ),
                },
            ]}
        />
    )
}