import React from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Col, Row } from 'antd';
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend"
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { PostRequest } from "../../components/backend/postRequest";
import { BreadcrumbsAdminUsersCreate } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { useState } from "react";
import { Helmet } from "react-helmet";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


export const UserCreate = () => {
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true)
        PostRequest(getBackendLink() + `/api/v1/users/admin/create`, {
            first_name: values.first_name,
            last_name: values.last_name,
            avatar_link: values.avatar_link,
            bh_email: values.bh_email,
            login: values.login,
            password: values.password,
            gmail: values.gmail,
            phone: values.phone,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        NotificationSuccess('Created!', <>User will appear in the list in a minute. <br />For now, you
                            might want to <a href={"/admin/users/edit/" + result.result}>edit</a> user roles</>)
                    }

                    setLoading(false)
                },
                (error) => {
                    NotificationError('Error', error.message)

                    setLoading(false)
                }
            )
    }

    return (
        <>
            <Helmet>
                <title>Create new user || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersCreate />
                    </Col>
                </Row>
            </div>
            <Row className="container-1700">
                <Col span={24} offset={0}>
                    <Form
                        name="register"
                        {...formItemLayout}
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            loading={loading}
                            name="first_name"
                            label="First name"
                            rules={[{ required: true, message: 'Name must not be empty' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last name"
                            rules={[{ required: true, message: 'Name must not be empty' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="avatar_link"
                            label="Avatar link"
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="bh_email"
                            label="Bangerhead email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: "Doesn't look like real email",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="gmail"
                            label="Google email"
                            rules={[
                                {
                                    type: 'email',
                                    message: "Doesn't look like real email",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                                {
                                    type: 'phone',
                                    message: "Doesn't look like real phone",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Repeat password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Passwords mismatch'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="login"
                            label="Login"
                            rules={[
                                {
                                    required: true,
                                    message: "Login must not be empty",
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                className="login-form-button">
                                Create user
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    )
}