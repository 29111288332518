import React from 'react';
import { Row, Col, Typography } from 'antd';
import { BreadcrumbsProfileSettings } from "../../components/breadcrumbs/breadcrumbsProfile";
import { SettingsProfile } from "../../subpages/profile/settingsProfile";
import { SettingsNotifications } from "../../subpages/profile/settingsNotifications";
import { Helmet } from "react-helmet";

const { Title } = Typography;

export const ProfileSettings = (props) => {
    return (
        <>
            <Helmet>
                <title>My settings || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProfileSettings />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Title level={3}>Main profile settings</Title>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <SettingsProfile />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Title level={3}>Notification settings</Title>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <SettingsNotifications messageAPI={props.messageAPI} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
