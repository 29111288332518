import { ErrorRow } from "../../components/errorBlock";
import React, { useEffect, useState } from "react";
import { Button, Modal, Popconfirm, Table, Tag } from "antd";
import { AccessGrantsCheckList } from "../../components/grantsList";
import { GetUserLink } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { CanExpireAccessTokens, CanRevokeAccessTokens } from "../../components/backend/backendRoles";
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { PostRequest } from "../../components/backend/postRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { GetRequest } from "../../components/backend/getRequest";
import { FormatShortUUID } from "../../components/formattingValues";

export const AccessTokensTable = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const fetchData = () => {
        setLoadingData(true);
        GetRequest(getBackendLink() + `/api/v1/security/access_token/list/json?page=page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records)
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    if (props.setTotal !== null && props.setTotal !== undefined) {
                        props.setTotal(result.result.Total)
                    }

                    setLoadingData(false);
                },
                (error) => {
                    setLoadingData(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchData();
        // eslint-disable-next-line
    }, [tableParams.pagination.pageSize, tableParams.pagination.current]);

    const [grantsOpen, setGrantsOpen] = useState(false);
    const GrantsPopup = ({ open, onCreate, onCancel }) => {
        return (
            <Modal
                open={open}
                okText="OK"
                cancelText="Cancel"
                onCancel={onCancel}
                title="Token grants"
            >
                <AccessGrantsCheckList userGrants={currentGrants} disableAll />
            </Modal>
        );
    };

    const handleRevoke = (tokenID = "") => {
        setLoadingData(true)
        PostRequest(getBackendLink() + `/api/v1/security/access_token/revoke`, {
            "tokenID": tokenID,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Revoked!')
                    let newData = data

                    if (Array.isArray(tokenID)) {
                        tokenID.forEach((element) => {
                            newData = newData.filter((item) => item.tokenID !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.tokenID !== tokenID);
                    }
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoadingData(false)
    };

    const handleExpire = (tokenID = "") => {
        setLoadingData(true)
        PostRequest(getBackendLink() + `/api/v1/security/access_token/expire`, {
            "tokenID": tokenID,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Expired!')
                    let newData = data

                    if (Array.isArray(tokenID)) {
                        tokenID.forEach((element) => {
                            newData = newData.filter((item) => item.tokenID !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.tokenID !== tokenID);
                    }
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoadingData(false)
    };

    const [currentGrants, setCurrentGrants] = useState([])
    const columns = [
        {
            title: 'ID',
            dataIndex: 'tokenID',
            key: 'tokenID',
        },
        {
            title: 'Owner',
            dataIndex: 'Owner',
            key: 'Owner',
            render: (_, record) => (
                <>{record.ownerName === "" ? <><a href={GetUserLink(record.ownerID)}>{record.ownerID}</a></> :
                    <a href={GetUserLink(record.ownerID)}>{record.ownerName}</a>}</>
            ),
        },
        {
            title: 'CreatedAt',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.createdAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'ExpiresAt',
            dataIndex: 'ExpiresAt',
            key: 'ExpiresAt',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.expiresAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'Creator',
            dataIndex: 'Creator',
            key: 'Creator',
            render: (_, record) => (
                <>{record.createdByName === "" ? <><a href={GetUserLink(record.createdBy)}>{record.createdBy}</a></> :
                    <a href={GetUserLink(record.createdBy)}>{record.createdByName}</a>}</>
            ),
        },
        {
            title: 'Session',
            dataIndex: 'Session',
            key: 'Session',
            render: (_, record) => (
                <>{record.sessionID === "" ? <></> :
                    <a href={`/admin/sessions/${record.sessionID}`}>{FormatShortUUID(record.sessionID)}</a>}
                    {props.sessionID === record.sessionID ? <Tag color={'lime'}>Current session</Tag> : <></>}
                </>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'Comment',
            key: 'Comment',
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (_, record) => (
                <>
                    <>
                        <Button
                            onClick={() => {
                                setGrantsOpen(true);
                                setCurrentGrants(record.grants);
                            }}
                            type="primary"
                        >
                            Grants
                        </Button>
                        {
                            CanExpireAccessTokens(props.grants)
                                ?
                                <Popconfirm
                                    title="Expire token"
                                    description="Are you sure to expire this token?"
                                    onConfirm={() => handleExpire(record.tokenID)}
                                    icon={
                                        <WarningOutlined
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Button type="dashed" danger
                                    >
                                        Expire
                                    </Button>
                                </Popconfirm>
                                :
                                <></>
                        }
                        {
                            CanRevokeAccessTokens(props.grants)
                                ?
                                <Popconfirm
                                    title="Revoke token"
                                    description="Are you sure to revoke this token?"
                                    onConfirm={() => handleRevoke(record.tokenID)}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Button type="dashed" danger>
                                        Revoke
                                    </Button>
                                </Popconfirm>
                                :
                                <></>
                        }
                    </>
                </>
            ),
        },
    ]

    if (err !== "") {
        return <ErrorRow err={err} prefix={"Access tokens"} />
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loadingData}
                size={"small"}
                onChange={handleTableChange}
            />
            <GrantsPopup
                open={grantsOpen}
                onCancel={() => {
                    setGrantsOpen(false);
                }}
            />
        </>
    )
}