import React from 'react';
import { Col, Row, Tabs } from "antd";
import { useParams } from "react-router-dom";
import {
    BreadcrumbsProductsProduct
} from "../../components/breadcrumbs/breadcrumbsProducts";
import 'react-json-pretty/themes/monikai.css';
import { ProductData } from "../../subpages/products/productData";
import { Helmet } from "react-helmet";

export const Product = () => {
    const { id } = useParams();

    return (
        <>
            <Helmet>
                <title>Item #{id} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsProduct sku={id} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="Product_data"
                            items={[
                                {
                                    label: 'Product data',
                                    key: 'Product_data',
                                    children:
                                        <>
                                            <ProductData id={id} />
                                        </>
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>

        </>
    )
}



// const [dataCampaigns, setDataCampaigns] = useState([]);
// const [loadingCampaigns, setLoadingCampaigns] = useState(true);
// const [errCampaigns, setErrCampaigns] = useState('');
// const [tableCampaignsParams, setTableCampaignsParams] = useState({
//     pagination: {
//         current: 1,
//         pageSize: 20,
//         position: ["topCenter", "bottomCenter"],
//     },
// });
//

//
// const fetchDataProduct = () => {
//     setLoadingProduct(true);
//     GetRequest(getBackendLink() + `/api/v1/products?sku=${id}`)
//         .then(
//             (result) => {
//                 if (result.success !== true) {
//                     actionOnError(result.error)
//                     setErrProduct(result.error)
//                 } else {
//                     setErrProduct("")
//                     setDataProduct(result.result.Product);
//                 }
//                 setLoadingProduct(false);
//             },
//             (error) => {
//                 setLoadingProduct(false);
//                 NotificationError('Error', error.message)
//             }
//         )
// };
// // eslint-disable-next-line
// useEffect(() => {
//     // eslint-disable-next-line
//     fetchDataProduct();
//     // eslint-disable-next-line
// }, []);
//
// const columnsCampaigns = [
//     {
//         title: 'Name',
//         dataIndex: 'Name',
//         key: 'Name',
//     },
//     {
//         title: 'Products',
//         dataIndex: 'NumberOfProducts',
//         key: 'NumberOfProducts',
//     },
//     {
//         title: 'Discount',
//         dataIndex: 'DiscountPercent',
//         key: 'DiscountPercent',
//         render: (_, record) => (
//             <>{record.DiscountPercent}%</>
//         ),
//     },
//     {
//         title: 'CreatedBy',
//         dataIndex: 'CreatedBy',
//         key: 'CreatedBy',
//         render: (_, record) => (
//             <>{record.CreatedByName === "" ? <><a href={GetUserLink(record.CreatedBy)}>{record.CreatedBy}</a></> :
//                 <a href={GetUserLink(record.CreatedBy)}>{record.CreatedByName}</a>}</>
//         ),
//     },
//     {
//         title: 'Created',
//         dataIndex: 'CreatedAt',
//         key: 'CreatedAt',
//         render: (_, record) => (
//             <><Moment format="YYYY.MM.DD HH:MM" date={record.CreatedAt}/></>
//         ),
//     },
//     {
//         title: 'Brand',
//         dataIndex: 'BrandName',
//         key: 'BrandName',
//     },
//     {
//         title: 'Market',
//         dataIndex: 'CountryName',
//         key: 'CountryName',
//     },
//     {
//         title: 'Category',
//         dataIndex: 'CategoryName',
//         key: 'CategoryName',
//     },
//     {
//         title: 'Start',
//         dataIndex: 'StartDate',
//         key: 'StartDate',
//         render: (_, record) => (
//             <><Moment format="YYYY.MM.DD" date={record.StartDate}/></>
//         ),
//     },
//     {
//         title: 'Due',
//         dataIndex: 'DueDate',
//         key: 'DueDate',
//         render: (_, record) => (
//             <><Moment format="YYYY.MM.DD" date={record.DueDate}/></>
//         ),
//     },
//     {
//         title: 'operation',
//         dataIndex: 'operation',
//         render: (_, record) =>
//             dataCampaigns.length >= 1 ? (
//                 <>
//                     <Space wrap>
//                         <Button type="primary" href={`/campaigns/${record.CampaignID}`} target={"_blank"}
//                                 rel={"noreferrer"}>
//                             <EyeOutlined/>
//                         </Button>
//                         <Button href={getBackendLink() + `/api/v1/products/campaigns/get_file?${record.CampaignID}`}
//                                 type="primary"
//                                 style={{backgroundColor: "green"}}
//                         >
//                             <CloudDownloadOutlined/>
//                         </Button>
//                     </Space>
//                 </>
//             ) : null,
//     },
// ];
//
// const fetchDataCampaigns = () => {
//     setLoadingCampaigns(true);
//     GetRequest(getBackendLink() + `/api/v1/products/campaigns/for_product?page=${tableCampaignsParams.pagination.current}&size=${tableCampaignsParams.pagination.pageSize}&sku=${id}`)
//         .then(
//             (result) => {
//                 if (result.success !== true) {
//                     actionOnError(result.error)
//                     setErrCampaigns(result.error)
//                 } else {
//                     setErrCampaigns("")
//                     setDataCampaigns(result.result.Records);
//                     setTableCampaignsParams({
//                         ...tableCampaignsParams,
//                         pagination: {
//                             ...tableCampaignsParams.pagination,
//                             total: result.result.Total,
//                         },
//                     });
//                 }
//                 setLoadingCampaigns(false);
//             },
//             (error) => {
//                 setLoadingCampaigns(false);
//                 NotificationError('Error', error.message)
//             }
//         )
// };
// // eslint-disable-next-line
// useEffect(() => {
//     // eslint-disable-next-line
//     fetchDataCampaigns()
//     // eslint-disable-next-line
// }, [JSON.stringify(tableCampaignsParams)]);
//
// const handleTableChange = (pagination, filters, sorter) => {
//     setTableCampaignsParams({
//         pagination,
//         filters,
//         ...sorter,
//     });
//
//     // `dataSource` is useless since `pageSize` changed
//     if (pagination.pageSize !== tableCampaignsParams.pagination?.pageSize) {
//         setDataCampaigns([]);
//     }
// };


// return (
//     <>
//         <div className="container-1700">
//             <Row>
//                     <Col xs={24} xl={24}>
//                     <BreadcrumbsProductsProduct sku={id}/>
//                 </Col>
//             </Row>
//         </div>
//         <div className="container-1700">
//             <Row>
//                 <Col xs={24} xl={24}>
//                     <InlineMenu items={GetProductPageMenuItems(id)}/>
//                 </Col>
//             </Row>
//         </div>
//         <div className="container-1700">
//            <ErrorRow prefix={"Product"} err={errProduct}/>
//            <ErrorRow prefix={"Campaigns"} err={errCampaigns}/>
//             <Row>
//                 <Col xs={24} xl={24}>
//                     <Tabs
//                         style={{paddng:"0 1em 0 1em", backgroundColor:"#ffffffb3"}}
//                         defaultActiveKey="BasicData"
//                         items={[
//                             {
//                                 label: 'Images',
//                                 key: 'Images',
//                                 children: loadingProduct === true ? <Spinner/> : ProductImages(dataProduct),
//                             },
//                             {
//                                 label: 'Basic data',
//                                 key: 'BasicData',
//                                 children: loadingProduct === true ? <Spinner/> : ProductBasicData(dataProduct),
//                             },
//                             {
//                                 label: 'Links',
//                                 key: 'Links',
//                                 children: loadingProduct === true ? <Spinner/> : ProductLinks(dataProduct),
//                             },
//                             {
//                                 label: 'Categories',
//                                 key: 'Cats',
//                                 children: loadingProduct === true ? <Spinner/> : ProductCats(dataProduct),
//                             },
//                             {
//                                 label: 'Descriptions',
//                                 key: 'Descrs',
//                                 children: loadingProduct === true ? <Spinner/> : ProductDescrs(dataProduct),
//                             },
//                             {
//                                 label: 'Titles',
//                                 key: 'Titles',
//                                 children: loadingProduct === true ? <Spinner/> : ProductTitles(dataProduct),
//                             },
//                             {
//                                 label: 'Prices',
//                                 key: 'Prices',
//                                 children: loadingProduct === true ? <Spinner/> : ProductPrices(dataProduct),
//                             },
//                             {
//                                 label: 'Vouchers & Deals',
//                                 key: 'VouchersDeals',
//                                 children: loadingProduct === true ? <Spinner/> : VouchersDeals(dataProduct),
//                             },
//                             {
//                                 label: 'Campaigns',
//                                 key: 'Campaigns',
//                                 children: loadingCampaigns === true ? <Spinner/> : <Table
//                                     dataSource={dataCampaigns}
//                                     columns={columnsCampaigns}
//                                     loading={loadingCampaigns}
//                                     pagination={tableCampaignsParams.pagination}
//                                     rowKey={(record) => record.CampaignID}
//                                     size={"small"}
//                                     onChange={handleTableChange}
//                                     showSizeChanger={true}
//                                 />,
//                             },
//                             {
//                                 label: 'Other',
//                                 key: 'Other',
//                                 children: loadingProduct === true ? <Spinner/> : ProductOther(dataProduct),
//                             },
//                         ]}
//                     />
//                 </Col>
//             </Row>
//         </div>
//     </>
// );
//
// export function ProductImages(data) {
//     return (
//         <Space wrap>
//             <Image style={{maxWidth: 300}} src={data.ImageURL}/>
//             <Image style={{maxWidth: 300}}
//                    src={`https://www.bangerhead.se/bilder/ibutik-a/varumarken/logo_liten/${data.BrandId}.gif`}/>
//         </Space>
//     )
// }
//
// export function ProductBasicData(data = {
//     Parent: "",
//     BrandId: "",
//     Mpn: "",
//     Gtin: "",
//     EAN: "",
//     Visibility: "",
//     BrandName: "",
//     ShippingClassId: "",
//     Offer: "",
//     VatId: "",
//     Supplier: "",
//     Created: "",
//     StockId: "",
//     BrandLimitCountry: "",
//     Weight: "",
//     BuyTogether: "",
//     Quantity: "",
//     InStock: "",
// }) {
//     return (
//         <Card title={`${data.ItemSKU} Basic Data`}>
//             {data.ItemSKU !== null && data.ItemSKU !== undefined
//                 ?
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item label={<>Product</>}>
//                         {data.ItemSKU}
//                     </Descriptions.Item>
//                     <Descriptions.Item label={<>Parent</>}>
//                         {data.Parent !== "" ? <a href={`/products/${data.Parent}`}>{data.Parent}</a> : <>---</>}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="Brand">{data.BrandName} ({data.BrandId})</Descriptions.Item>
//                     <Descriptions.Item label="Mpn">{data.Mpn}</Descriptions.Item>
//                     <Descriptions.Item label="EAN">{data.EAN}</Descriptions.Item>
//                     <Descriptions.Item label="Gtin">{data.Gtin}</Descriptions.Item>
//                     <Descriptions.Item label="Visibility">{data.Visibility}</Descriptions.Item>
//                     <Descriptions.Item label="ShippingClassId">{data.ShippingClassId}</Descriptions.Item>
//                     <Descriptions.Item label="Offer">{data.Offer}</Descriptions.Item>
//                     <Descriptions.Item label="VatId">{data.VatId}</Descriptions.Item>
//                     <Descriptions.Item label="Supplier">{data.Supplier}</Descriptions.Item>
//                     <Descriptions.Item label="Created"><Moment format="YYYY.MM.DD HH:MM"
//                                                                date={data.Created}/></Descriptions.Item>
//                     <Descriptions.Item label="StockId">{data.StockId}</Descriptions.Item>
//                     <Descriptions.Item label="BrandLimitCountry">{data.BrandLimitCountry}</Descriptions.Item>
//                     <Descriptions.Item label="Weight">{data.Weight}</Descriptions.Item>
//                     <Descriptions.Item label="BuyTogether">{data.BuyTogether}</Descriptions.Item>
//                     <Descriptions.Item label="Quantity">{data.Quantity}</Descriptions.Item>
//                     <Descriptions.Item label="InStock">{data.InStock}</Descriptions.Item>
//                 </Descriptions>
//                 :
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item>
//                         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                     </Descriptions.Item>
//                 </Descriptions>
//             }
//         </Card>
//     )
// }
//
// export function ProductLinks(data = {ProductLink: {}, ParentLink: {}}) {
//     return (
//         <Space wrap>
//             <Card title={`${data.ItemSKU} - links`}>
//                 <Descriptions size={"small"}>
//                     {data.ProductLink !== null && data.ProductLink !== undefined
//                         ?
//                         Object.keys(data.ProductLink).map((market, i) => (
//                             <Descriptions.Item label={market}>
//                                 <a target={"_blank"} rel={"noreferrer"}
//                                    href={GetProductLinkByMarket(market, data.ProductLink[market], data.ParentLink[market], data.ItemSKU)}>{GetProductLinkByMarket(market, data.ProductLink[market], data.ParentLink[market], data.ItemSKU)}</a>
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item>
//                                 <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                             </Descriptions.Item>
//                         </Descriptions>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - parent links`}>
//                 <Descriptions size={"small"}>
//                     {data.ParentLink !== null && data.ParentLink !== undefined
//                         ?
//                         Object.keys(data.ParentLink).map((market, i) => (
//                             <Descriptions.Item label={market}>
//                                 <a target={"_blank"} rel={"noreferrer"}
//                                    href={GetProductLinkByMarket(market, data.ParentLink[market])}>{GetProductLinkByMarket(market, data.ParentLink[market])}</a>
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item>
//                                 <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                             </Descriptions.Item>
//                         </Descriptions>
//                     }
//                 </Descriptions>
//             </Card>
//         </Space>
//     )
// }
//
// export function ProductCats(data = {
//     ItemSKU: "",
//     Categories: {
//         GroupName_cat1: "", GroupName_cat1_Id: "", GroupName_cat1_ParentId: "",
//         GroupName_cat2: "", GroupName_cat2_Id: "", GroupName_cat2_ParentId: "",
//         GroupName_cat3: "", GroupName_cat3_Id: "", GroupName_cat3_ParentId: "",
//     }
// }) {
//     return (
//         <Card title={`${data.ItemSKU} - Categories`}>
//             {data.Categories !== null && data.Categories !== undefined
//                 ?
//                 <Descriptions size={"small"}>
//                     {Object.keys(data.Categories).map((market, i) => (
//                         <Descriptions.Item label={market}>
//                             {data.Categories[market].GroupName_cat1 !== "" ? data.Categories[market].GroupName_cat1 : <></>}
//                             {data.Categories[market].GroupName_cat1_Id !== "" ? <> ({data.Categories[market].GroupName_cat1_Id})</> : <></>}
//                             {data.Categories[market].GroupName_cat1_ParentId !== "" ? <> ({data.Categories[market].GroupName_cat1_ParentId})</> : <></>}
//                             {data.Categories[market].GroupName_cat2 !== "" ? <> {data.Categories[market].GroupName_cat2}</> : <></>}
//                             {data.Categories[market].GroupName_cat2_Id !== "" ? <> ({data.Categories[market].GroupName_cat2_Id})</> : <></>}
//                             {data.Categories[market].GroupName_cat2_ParentId !== "" ? <> ({data.Categories[market].GroupName_cat2_ParentId})</> : <></>}
//                             {data.Categories[market].GroupName_cat3 !== "" ? <> {data.Categories[market].GroupName_cat3}</> : <></>}
//                             {data.Categories[market].GroupName_cat3_Id !== "" ? <> ({data.Categories[market].GroupName_cat3_Id})</> : <></>}
//                             {data.Categories[market].GroupName_cat3_ParentId !== "" ? <> ({data.Categories[market].GroupName_cat3_ParentId})</> : <></>}
//                         </Descriptions.Item>
//                     ))}
//                 </Descriptions>
//                 :
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item>
//                         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                     </Descriptions.Item>
//                 </Descriptions>
//             }
//         </Card>
//     )
// }
//
// export function ProductDescrs(data) {
//     return (
//         <Space wrap>
//             {data.Description !== null && data.Description !== undefined
//                 ?
//                 Object.keys(data.Description).map((market, i) => (
//                     <Card title={`${data.ItemSKU} - descriptions ${market}`}>
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item>
//                                 {data.Description[market]}
//                             </Descriptions.Item>
//                         </Descriptions>
//                     </Card>
//                 ))
//                 :
//                 <Card title={`${data.ItemSKU} - descriptions`}>
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 </Card>
//             }
//             {data.DescriptionParent !== null && data.DescriptionParent !== undefined
//                 ?
//                 Object.keys(data.DescriptionParent).map((market, i) => (
//                     <Card title={`${data.ItemSKU} - parent descriptions ${market}`}>
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item>
//                                 {data.DescriptionParent[market]}
//                             </Descriptions.Item>
//                         </Descriptions>
//                     </Card>
//                 ))
//                 :
//                 <Card title={`${data.ItemSKU} - parent descriptions`}>
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 </Card>
//             }
//         </Space>
//     )
// }
//
// export function ProductTitles(data = {ItemSKU: "", Titles: {}}) {
//     return (
//         <Card title={`${data.ItemSKU} - Titles`}>
//             {data.Titles !== null && data.Titles !== undefined
//                 ?
//                 <Descriptions size={"small"}>
//                     {Object.keys(data.Titles).map((market, i) => (
//                         <Descriptions.Item label={market}>
//                             {data.Titles[market]}
//                         </Descriptions.Item>
//                     ))}
//                 </Descriptions>
//                 :
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item>
//                         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                     </Descriptions.Item>
//                 </Descriptions>
//             }
//         </Card>
//     )
//
// }
//
// export function ProductPrices(data = {
//     ItemSKU: "",
//     CompetitorPricing: [],
//     RecommendedRetailPrices: [],
//     ProductPriceCurrencies: [],
//     CurrentPrices: []
// }) {
//     return (
//         <Space wrap>
//             <Card title={`${data.ItemSKU} - Prices`}>
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item label={"Price"}>{data.Price}</Descriptions.Item>
//                     <Descriptions.Item label={"PriceExtra"}>{data.PriceExtra}</Descriptions.Item>
//                     <Descriptions.Item label={"PurchasePrice"}>{data.PurchasePrice}</Descriptions.Item>
//                     <Descriptions.Item
//                         label={"IsSetPrice"}>{data.IsSetPrice === true ? "true" : "false"}</Descriptions.Item>
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - RecommendedRetailPrices`}>
//                 {data.RecommendedRetailPrices !== null && data.RecommendedRetailPrices !== undefined && data.RecommendedRetailPrices.length > 0
//                     ?
//                     <Descriptions size={"small"}>
//                         {Object.keys(data.RecommendedRetailPrices).map((id, i) => (
//                             <Descriptions.Item label={`${data.RecommendedRetailPrices[id].Currency}`}>
//                                 {data.RecommendedRetailPrices[id].Price}
//                             </Descriptions.Item>
//                         ))}
//                     </Descriptions>
//                     :
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 }
//             </Card>
//             <Card title={`${data.ItemSKU} - CompetitorPricing`}>
//                 {data.ProductPriceCurrencies !== null && data.ProductPriceCurrencies !== undefined && data.ProductPriceCurrencies.length > 0
//                     ?
//                     Object.keys(data.ProductPriceCurrencies).map((id, i) => (
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item label={`${data.ProductPriceCurrencies[id].Currency}`}>
//                                 {data.ProductPriceCurrencies[id].Price},
//                                 PriceList: {data.ProductPriceCurrencies[id].PriceList},
//                                 PriceType: {data.ProductPriceCurrencies[id].PriceType}
//                             </Descriptions.Item>
//                         </Descriptions>
//                     ))
//                     :
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 }
//             </Card>
//             <Card title={`${data.ItemSKU} - CurrentPrices`}>
//                 {data.CurrentPrices !== null && data.CurrentPrices !== undefined && data.CurrentPrices.length > 0
//                     ?
//                     Object.keys(data.CurrentPrices).map((id, i) => (
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item label={`${data.CurrentPrices[id].Currency}`}>
//                                 Price: {data.CurrentPrices[id].Price},
//                                 ExtraPrice: {data.CurrentPrices[id].ExtraPrice},
//                                 SellingPrice: {data.CurrentPrices[id].SellingPrice},
//                                 RecommendedPrice: {data.CurrentPrices[id].RecommendedPrice},
//                                 PurchasePrice: {data.CurrentPrices[id].PurchasePrice},
//                             </Descriptions.Item>
//                         </Descriptions>
//                     ))
//                     :
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 }
//             </Card>
//             <Card title={`${data.ItemSKU} - CompetitorPricing`}>
//                 {data.CompetitorPricing !== null && data.CompetitorPricing !== undefined && data.CompetitorPricing.length > 0
//                     ?
//                     Object.keys(data.CompetitorPricing).map((id, i) => (
//                         <Descriptions size={"small"}>
//                             <Descriptions.Item
//                                 label={`${data.CompetitorPricing[id].Retailer} (${data.CompetitorPricing[id].Country})`}>
//                                 Created: {<Moment format="YYYY.MM.DD HH:MM"
//                                                   date={data.CompetitorPricing[id].CreatedAt}/>},
//                                 Campaign: {data.CompetitorPricing[id].Campaign === true ? "true" : "false"}
//                             </Descriptions.Item>
//                         </Descriptions>
//                     ))
//                     :
//                     <Descriptions size={"small"}>
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     </Descriptions>
//                 }
//             </Card>
//         </Space>
//     )
// }
//
// export function ProductOther(data = {ItemSKU: "", Titles: {}}) {
//     return (
//         <Space wrap>
//             <Card title={`${data.ItemSKU} - Feature`}>
//                 <Descriptions size={"small"}>
//                     {data.Feature !== null && data.Feature !== undefined
//                         ?
//                         Object.keys(data.Feature).map((market, i) => (
//                             <Descriptions.Item label={market}>
//                                 {data.Feature[market]}
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - Eko`}>
//                 <Descriptions size={"small"}>
//                     {data.Eko !== null && data.Eko !== undefined
//                         ?
//                         Object.keys(data.Eko).map((market, i) => (
//                             <Descriptions.Item label={market}>
//                                 {data.Eko[market]}
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - Vegan`}>
//                 <Descriptions size={"small"}>
//                     {data.Vegan !== null && data.Vegan !== undefined
//                         ?
//                         Object.keys(data.Vegan).map((market, i) => (
//                             <Descriptions.Item label={market}>
//                                 {data.Vegan[market]}
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - ProductGroups`}>
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item label="DefaultGroup">{data.DefaultGroup}</Descriptions.Item>
//                     {data.ProductGroups !== null && data.ProductGroups !== undefined && data.ProductGroups.length > 0
//                         ?
//                         Object.keys(data.ProductGroups).map((id, i) => (
//                             <Descriptions.Item label={id}>
//                                 {data.ProductGroups[id]}
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - Other`}>
//                 <Descriptions size={"small"}>
//                     <Descriptions.Item label={"FreeShippingLimit"}>
//                         {JSON.stringify(data.FreeShippingLimit)}
//                     </Descriptions.Item>
//                     <Descriptions.Item label={"ProductSet"}>
//                         {JSON.stringify(data.ProductSet)}
//                     </Descriptions.Item>
//                     <Descriptions.Item label={"Features"}>
//                         {JSON.stringify(data.Features)}
//                     </Descriptions.Item>
//                     <Descriptions.Item label={"DeliveryCost"}>
//                         {JSON.stringify(data.DeliveryCost)}
//                     </Descriptions.Item>
//                 </Descriptions>
//             </Card>
//         </Space>
//     )
// }
//
// export function VouchersDeals(data = {ItemSKU: "", VoucherCodes: [], Deals: []}) {
//     return (
//         <Space wrap>
//             <Card title={`${data.ItemSKU} - Deals`}>
//                 <Descriptions size={"small"}>
//                     {data.Deals !== null && data.Deals !== undefined && data.Deals.length > 0
//                         ?
//                         Object.keys(data.Deals).map((id, i) => (
//                             <Descriptions.Item label={data.Deals[id].Currency}>
//                                 DealType: {data.Deals[id].DealType},
//                                 DealStartDateTime: {data.Deals[id].DealStartDateTime},
//                                 DealEndDateTime: {data.Deals[id].DealEndDateTime},
//                                 DealRedeemDateTime: {data.Deals[id].DealRedeemDateTime},
//                                 DealDiscountAmount: {data.Deals[id].DealDiscountAmount},
//                                 DealDiscountPercentage: {data.Deals[id].DealDiscountPercentage},
//                                 CalculatedPrice: {data.Deals[id].CalculatedPrice},
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//             <Card title={`${data.ItemSKU} - VoucherCodes`}>
//                 <Descriptions size={"small"}>
//                     {data.VoucherCodes !== null && data.VoucherCodes !== undefined && data.VoucherCodes.length > 0
//                         ?
//                         Object.keys(data.VoucherCodes).map((id, i) => (
//                             <Descriptions.Item label={data.VoucherCodes[id].Currency}>
//                                 DiscountType: {data.VoucherCodes[id].DiscountType},
//                                 VoucherValue: {data.VoucherCodes[id].VoucherValue},
//                                 CalculatedPrice: {data.VoucherCodes[id].CalculatedPrice}
//                             </Descriptions.Item>
//                         ))
//                         :
//                         <Descriptions.Item>
//                             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
//                         </Descriptions.Item>
//                     }
//                 </Descriptions>
//             </Card>
//         </Space>
//     )
// }
//
// export function GetProductLinkByMarket(market = "", link = "", parentLink = "", sku = "") {
//     if (market === "SE" || market === "SV") {
//         if (parentLink !== "") {
//             return `https://bangerhead.se${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.se${link}`
//         }
//     }
//     if (market === "NO") {
//         if (parentLink !== "") {
//             return `https://bangerhead.no${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.no${link}`
//         }
//     }
//     if (market === "FI") {
//         if (parentLink !== "") {
//             return `https://bangerhead.fi${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.fi${link}`
//         }
//     }
//     if (market === "DK") {
//         if (parentLink !== "") {
//             return `https://bangerhead.dk${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.dk${link}`
//         }
//     }
//     if (market === "NL") {
//         if (parentLink !== "") {
//             return `https://bangerhead.nl${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.nl${link}`
//         }
//     }
//     if (market === "PL") {
//         if (parentLink !== "") {
//             return `https://bangerhead.pl${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.pl${link}`
//         }
//     }
//     if (market === "BE") {
//         if (parentLink !== "") {
//             return `https://bangerhead.be${parentLink}?variant=${sku}`
//         }
//         if (link !== "") {
//             return `https://bangerhead.be${link}`
//         }
//     }
//
//     return ""
// }