import React from "react";
import { Col, Row } from "antd";
import { BreadcrumbsDeveloperProductsFeeds } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { Helmet } from "react-helmet";

export const DeveloperProductsFeeds = () => {
    return (
        <>
            <Helmet>
                <title>Dev: product feeds || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperProductsFeeds />
                    </Col>
                </Row>
            </div>
        </>
    )
}