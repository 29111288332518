import { Col, Row, Table, Tag } from "antd";
import React from "react";
import { BreadcrumbsDeveloperServicesRuns } from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { useState } from "react";
import { GetRequestInternalAPI } from "../../../../components/backend/getRequest";
import { useEffect } from "react";
import { GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../../../components/dateAndTime";
import { GetApplicationRunLink } from "../../../../components/getPageLinks";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, CloudSyncOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../../../components/errorBlock";
import { Helmet } from "react-helmet";
import { GetEventSourcesTag } from "../../../../components/getEventsData";

const columns = [
    {
        title: 'RunID',
        dataIndex: 'RunID',
        key: 'RunID',
        render: (_, record) => (
            <>{GetApplicationRunLink(record.RunID)}</>
        ),
    },
    {
        title: 'Application',
        dataIndex: 'Application',
        key: 'Application',
        render: (_, record) => (
            <GetEventSourcesTag source={record.SourceID} />
        ),
    },
    {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        render: (_, record) => (
            <>{record.SourceType}</>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (_, record) => (
            <>
                {
                    record.IsStopped
                        ?
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Done
                        </Tag>
                        :
                        <>
                            {record.SourceType === "cron" ?
                                <Tag icon={<SyncOutlined spin />} color="processing"> Processing</Tag> : <></>}
                            {record.SourceType === "service" ?
                                <Tag icon={<CloudSyncOutlined />} color="cyan">Online</Tag> : <></>}
                        </>





                }
            </>
        ),
    },
    {
        title: 'Result',
        dataIndex: 'Result',
        key: 'Result',
        render: (_, record) => (
            <>
                {
                    record.IsStopped === true
                        ?
                        record.Result === "OK"
                            ?
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                OK
                            </Tag>
                            :
                            <Tag icon={<CloseCircleOutlined />} color="error">
                                Error
                            </Tag>
                        :
                        <></>
                }
            </>
        ),
    },
    {
        title: 'Started',
        dataIndex: 'Started',
        key: 'Started',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.StartedAt.seconds * 1000} /></>
        ),
    },
    {
        title: 'Ended',
        dataIndex: 'Ended',
        key: 'Ended',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.StoppedAt.seconds * 1000} /></>
        ),
    },
    {
        title: 'Description',
        dataIndex: 'Text',
        key: 'Text',
    },
    {
        title: 'Metadata',
        dataIndex: 'Metadata',
        key: 'Metadata',
    },
];

export const Runs = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [before] = useState(GetDefaultBefore());
    const [after] = useState(GetDefaultAfter());
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const getData = () => {
        GetRequestInternalAPI(
            `/api/v1/logs/app-runs-get-list?before=${before}&after=${after}&page=${tableParams.pagination.current}&per_page=${tableParams.pagination.pageSize}`,
            setLoading,
            setErr,
            null,
        ).then(
            (result = {}) => {
                if (result.records !== null) {
                    setData(result.records)
                }

                if (result.total !== null && result.total !== undefined) {
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            }
        )
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>Application runs || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsDeveloperServicesRuns />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Runs"} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Application runs || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesRuns />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.RunID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )

}