import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Tabs, Alert } from 'antd';
import { Tag } from 'antd';
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { ErrorRow } from "../../components/errorBlock";
import { NotificationError } from "../../components/notifications";
import { Spinner } from "../../components/spinners";
import { BreadcrumbsProfileSecurity } from "../../components/breadcrumbs/breadcrumbsProfile";
import { AccessGrantsCheckList } from "../../components/grantsList";
import { SecuritySessionsClosed } from "../../subpages/profile/securitySessionsClosed";
import { SecuritySessionsActive } from "../../subpages/profile/securitySessionsActive";
import { OwnAccessTokensTable } from "../../subpages/profile/ownAccessTokensTable";
import { SecurityFailedLoginAttempts } from "../../subpages/profile/securityFailedLoginAttempts";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";

export const ProfileSecurity = (props) => {
    const [passUpdated, setPassUpdated] = useState("");
    const [passStr, setPassStr] = useState(0);
    const [loadingBasicData, setLoadingBasicData] = useState(false);
    const [basicErr, setBasicErr] = useState("");

    const fetchBasicData = () => {
        setLoadingBasicData(true);
        GetRequest(getBackendLink() + `/api/v1/users/self/security/basic/get`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setBasicErr(result.error)
                    } else {
                        setBasicErr("")
                        setPassUpdated(result.result.password_updated_at)
                        setPassStr(result.result.password_strength)
                    }
                    setLoadingBasicData(false);
                },
                (error) => {
                    setLoadingBasicData(false);
                    setBasicErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchBasicData();
    }, []);

    return (
        <>
            <Helmet>
                <title>My security || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProfileSecurity />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Typography.Title level={3}>
                            Security settings
                        </Typography.Title>
                    </Col>
                </Row>
                <Row>
                    {loadingBasicData ? <Spinner /> :
                        <>
                            <Col xs={24} xl={24}>
                                Password updated: <TimeFromSeconds seconds={passUpdated} />
                                <br />
                                Password strength: {passStr < 70 ? <><Tag color={'volcano'}>{passStr}</Tag></> : <><Tag
                                    color={'lime'}>{passStr}</Tag></>}
                            </Col>
                        </>
                    }
                </Row>
                {basicErr !== "" ? <ErrorRow prefix={"Error"} err={basicErr} /> : <></>}
                <Row style={{ marginTop: "1em", marginBottom: 16 }}>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="BasicData"
                            items={[
                                {
                                    label: 'Active sessions',
                                    key: 'Active_sessions',
                                    children:
                                        <>
                                            <SecuritySessionsActive token={props.token} />
                                        </>
                                },
                                {
                                    label: 'Closed sessions',
                                    key: 'Closed_sessions',
                                    children:
                                        <>
                                            <SecuritySessionsClosed token={props.token} />
                                        </>
                                },
                                {
                                    label: 'Failed login attempts',
                                    key: 'Failed_login_attempts',
                                    children:
                                        <>
                                            <SecurityFailedLoginAttempts />
                                        </>
                                },
                                {
                                    label: 'Access tokens',
                                    key: 'Access_tokens',
                                    children:
                                        <>
                                            <OwnAccessTokensTable />
                                        </>
                                },
                                {
                                    label: 'My access grants',
                                    key: 'My_access_grants',
                                    children:
                                        <>
                                            <Alert
                                                message={"Grants are shown for current session. Please, relogin if you have been given new grants and they have not appeared here"}
                                                type="info">
                                            </Alert>
                                            <AccessGrantsCheckList activeUserGrants={props.grants} disableAll />
                                        </>,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

