import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Tag } from 'antd';
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { ErrorRow } from "../../../components/errorBlock";
import { NotificationError } from "../../../components/notifications";
import { GetAppLink } from "../../../components/getPageLinks";
import {
    BreadcrumbsDeveloperEndpoints
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { GetTagFromValue } from "../../../components/formattingValues";
import { Helmet } from "react-helmet";

export const DeveloperEndpointsList = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const fetchData = () => {
        setLoadingData(true);
        GetRequest(getBackendLink() + `/api/v1/dev/endpoints/get_list/json?page=1&size=500`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.records)
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.total,
                            },
                        });
                    }
                    setLoadingData(false);
                },
                (error) => {
                    setLoadingData(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchData();
        // eslint-disable-next-line
    }, [tableParams.pagination.pageSize, tableParams.pagination.current]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };


    return (
        <>
            <Helmet>
                <title>Endpoint list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperEndpoints />
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        Total: <Tag color={"purple"}>{tableParams.pagination.total}</Tag>
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"Logs"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loadingData}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

const columns = [
    {
        title: 'App',
        dataIndex: 'App',
        key: 'App',
        render: (_, record) => (
            <>
                {record.app_id !== "" ? <><a href={GetAppLink(record.app_id)}>{record.app_name}</a></> : <>{record.app_name}</>}
            </>
        ),
    },
    {
        title: 'Proto',
        dataIndex: 'proto',
        key: 'proto',
    },
    {
        title: 'Method',
        dataIndex: 'method',
        key: 'method',
        render: (_, record) => (
            <>
                {GetTagFromValue(record.method)}
            </>
        ),
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (_, record) => (
            <>
                {record.method === "GET" && record.app_id === 5 ? <a target={"_blank"} rel={"noreferrer"} href={getBackendLink() + record.address}>{record.address}</a> : <>{record.address}</>}
            </>
        ),
    },
    {
        title: 'Request format',
        dataIndex: 'request_format',
        key: 'request_format',
        render: (_, record) => (
            <>
                {GetTagFromValue(record.request_format)}
            </>
        ),
    },
    {
        title: 'Response format',
        dataIndex: 'response_format',
        key: 'response_format',
        render: (_, record) => (
            <>
                {GetTagFromValue(record.response_format)}
            </>
        ),
    },
    {
        title: 'Active',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (_, record) => (
            <>
                {record.is_active ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}
            </>
        ),
    },
    {
        title: 'Requires auth',
        dataIndex: 'requires_auth',
        key: 'requires_auth',
        render: (_, record) => (
            <>
                {record.requires_auth ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}
            </>
        ),
    },
    {
        title: 'Requires session',
        dataIndex: 'requires_session',
        key: 'requires_session',
        render: (_, record) => (
            <>
                {record.requires_session ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}
            </>
        ),
    },
    {
        title: 'Requires token',
        dataIndex: 'requires_token',
        key: 'requires_token',
        render: (_, record) => (
            <>
                {record.requires_token ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}
            </>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
    },
    {
        title: 'Actions',
        dataIndex: 'Actions',
        key: 'Actions',
        render: (_, record) => (
            <>
                {<a target={"_blank"} rel={"noreferrer"} href={`/developer/endpoints/${record.id}`}>More</a>}
            </>
        ),
    },
]

