import React from 'react';
import { Col, Row, Space, Table } from "antd";
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { errorTranslation } from "../../../components/backend/backend";
import { NotificationError } from "../../../components/notifications";
import { useEffect, useState } from 'react';
import { GetAppLink } from "../../../components/getPageLinks";
import { ErrorBlock } from "../../../components/errorBlock"
import { GetTagFromValue } from "../../../components/formattingValues";
import {
    BreadcrumbsDeveloperIntegrationsStatuses
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { TimeFromSeconds } from "../../../components/dateAndTime";

const columns = [
    {
        title: 'Time',
        dataIndex: 'Timestamp',
        key: 'Timestamp',
        render: (_, record) => (
            <>{record.Timestamp.Valid === true ?
                <TimeFromSeconds seconds={record.Timestamp.Time} /> : <></>}</>
        ),
    },
    {
        title: 'AppID',
        dataIndex: 'AppID',
        key: 'AppID',
        render: (_, record) => (
            <><a href={GetAppLink(record.AppID)}>{record.AppName}</a></>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'AppStatusText',
        key: 'AppStatusText',
        render: (_, record) => (
            <>{GetTagFromValue(record.AppStatusText)}</>
        ),
    },
    {
        title: 'Result',
        dataIndex: 'Result',
        key: 'Result',
    },
    {
        title: 'Received',
        dataIndex: 'CreatedAt',
        key: 'CreatedAt',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.CreatedAt} /></>
        ),
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
            <Space size="middle">
                <a href={GetAppLink(record.AppID)}>App page</a>
            </Space>
        ),
    },
];

export const DeveloperIntegrationsStatuses = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/apps/statuses_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&app=0`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };
    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperIntegrationsStatuses />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.product}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}