import React from 'react';
import { useParams } from "react-router-dom";
import { Col, Row } from 'antd';
import {
    BreadcrumbsProductsProduct,
} from "../../components/breadcrumbs/breadcrumbsProducts";
import { ProductData } from "../../subpages/products/productData";
import { Helmet } from "react-helmet";

export function ProductCached() {
    const { id } = useParams();

    return (
        <>
            <Helmet>
                <title>Item #{id} (cached) || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsProduct sku={id} />
                    </Col>
                </Row>
            </div>
            <ProductData id={id} cached={true} />
        </>
    )
}
