import { getBackendLink } from "./backend/backend";

export var MoreFloAPIProductModel = {
    ArticleName: "",
    AccountCodeId: "",
    ArticleInfoUpdateTime: "",
    ArticleType: "",
    Barcode: "",
    BarcodeAlternatives: "",
    GroupId: "",
    InputPrice: "",
    QuantityUpdatedTime: "",
    RecommendedPrice: "",
    RetailPrice: "",
    Unit: "",
}

export const Groups = {
    "81024206-36b4-4d9e-a6f7-fa9641743b0c": "Exclude barcode sync",
    "3f5a51f5-2509-47d6-99cf-6ce15c61ffed": "Include barcode sync",
    "b0a043a3-8c8d-4ca0-ab28-88346917be8a": "Tjänst"
}

export const AccountCodes = {
    "4a7cdfa8-175e-4200-893c-8781c5ce193d": "Standard25"
}


export function GetLinkProductMoreFloAPI(sku = '') {
    return `https://api.moreflo.com/v3/articles/article?ArticleNumber=${sku}`
}

export function GetLinkCSVMoreFloHistory(batch, sku, barcode) {
    return getBackendLink() + `/api/v1/integrations/more_flo/cache_records_get_csv?batch_id=${batch}&sku=${sku}&barcode=${barcode}`
}

export function GetLinkProductMoreFlo(sku = '') {
    return `/products/${sku}/more_flo`
}