export const Grants = {
    Grants: {
        See: "grants_see",
        Add: "grants_add",
        Remove: "grants_remove",
        Admin: "grants_admin",
    },
    Session: {
        See: "session_see",
        Create: "session_create",
        Close: "session_close",
        Update: "session_update",
    },
    AccessToken: {
        See: "access_token_see",
        Add: "access_token_add",
        Expire: "access_token_expire",
        Revoke: "access_token_revoke",
    },
    Security: {
        Logs: {
            See: "security_logs_see",
            Add: "security_logs_add",
        },
        IP: {
            See: "ip_see",
            Block: "ip_block",
        },
    },
    Integrations: {
        See: "integration_see",
        Admin: "integration_admin",
        Slim4: {
            See: "integration_slim4_see",
            Admin: "integration_slim4_admin",
        },
        MoreFlo: {
            See: "integration_moreflo_see",
            Admin: "integration_moreflo_admin",
        },
        ClickUp: {
            See: "integration_clickup_see",
            Admin: "integration_clickup_admin",
        }
    },
    Developer: {
        Info: {
            See: "developer_info_see",
            Write: "developer_info_write",
        },
        Events: {
            Create: "developer_events_create",
            Resolve: "developer_events_resolve",
        },
        Admin: "developer_admin",
    },
    Users: {
        See: "users_see",
        Add: "users_add",
        Edit: "users_edit",
        Remove: "users_remove",
        Admin: "users_admin",
        ChangePassword: "users_change_password",
        EnableDisable: "users_enable_disable",
    },
    Statistic: {
        See: "statistic_see",
        Logs: {
            See: "logs_see",
            Add: "logs_add",
        },
    },
    Products: {
        See: "products_see",
        Write: "products_write",
        Campaigns: {
            See: "campaigns_see",
            Create: "campaigns_create",
            Edit: "campaigns_edit",
            Delete: "campaigns_delete",
        },
    },
    Administration: {
        Administrator: "administrator",
    },
    Notifications: {
        Messages: {
            Push: "messages_push"
        },
        Events: {
            See: "event_see",
            Create: "event_create",
            Resolve: "event_resolve"
        }
    }
}

export function GetGrantsSelectList(grants) {
    return [
        {
            label: <span>Products</span>,
            title: 'Products',
            options: [
                {
                    label: <span>Products: See</span>,
                    value: 'products_see',
                },
                {
                    label: <span>Products: Write</span>,
                    value: 'products_write',
                },
            ],
        },
        {
            label: <span>Campaigns</span>,
            title: 'Campaigns',
            options: [
                {
                    label: <span>Campaigns: See</span>,
                    value: 'campaigns_see',
                },
                {
                    label: <span>Campaigns: Create</span>,
                    value: 'campaigns_create',
                },
                {
                    label: <span>Campaigns: Edit</span>,
                    value: 'campaigns_edit',
                },
                {
                    label: <span>Campaigns: Delete</span>,
                    value: 'campaigns_delete',
                },
            ],
        },
        {
            label: <span>Statistic</span>,
            title: 'Statistic',
            options: [
                {
                    label: <span>Statistic: See</span>,
                    value: 'statistic_see',
                },
                {
                    label: <span>Logs: See</span>,
                    value: 'logs_see',
                },
                {
                    label: <span>Logs: Add</span>,
                    value: 'logs_add',
                },
            ],
        },
        {
            label: <span>Notifications</span>,
            title: 'Statistic',
            options: [
                {
                    label: <span>Messages: push</span>,
                    value: 'messages_push',
                },
                {
                    label: <span>Events: see</span>,
                    value: 'event_see',
                },
                {
                    label: <span>Events: create</span>,
                    value: 'event_create',
                },
                {
                    label: <span>Events: resolve</span>,
                    value: 'event_resolve',
                },
            ],
        },
        {
            label: <span>Users</span>,
            title: 'Users',
            options: [
                {
                    label: <span>Users: See</span>,
                    value: 'users_see',
                    disabled: !CanSeeUsers(grants)
                },
                {
                    label: <span>Users: Add</span>,
                    value: 'users_add',
                    disabled: !CanCreateUsers(grants)
                },
                {
                    label: <span>Users: Edit</span>,
                    value: 'users_edit',
                    disabled: !CanEditUsers(grants)
                },
                {
                    label: <span>Users: Remove</span>,
                    value: 'users_remove',
                },
                {
                    label: <span>Users: Admin</span>,
                    value: 'users_admin',
                },
                {
                    label: <span>Users: ChangePassword</span>,
                    value: 'users_change_password',
                },
                {
                    label: <span>Users: EnableDisable</span>,
                    value: 'users_enable_disable',
                },
            ],
        },
        {
            label: <span>Security</span>,
            title: 'Security',
            options: [
                {
                    label: <span>Logs: See</span>,
                    value: 'security_logs_see',
                },
                {
                    label: <span>Logs: Add</span>,
                    value: 'security_logs_add',
                },
                {
                    label: <span>IP: See</span>,
                    value: 'ip_see',
                },
                {
                    label: <span>IP: Block</span>,
                    value: 'ip_block',
                },
            ],
        },
        {
            label: <span>Integrations</span>,
            title: 'Integrations',
            options: [
                {
                    label: <span>Integrations: See</span>,
                    value: 'integration_see',
                },
                {
                    label: <span>Integrations: Admin</span>,
                    value: 'integration_admin',
                },
                {
                    label: <span>Slim4: See</span>,
                    value: 'integration_slim4_see',
                },
                {
                    label: <span>Slim4: Admin</span>,
                    value: 'integration_slim4_admin',
                },
                {
                    label: <span>MoreFlo: See</span>,
                    value: 'integration_moreflo_see',
                },
                {
                    label: <span>MoreFlo: Admin</span>,
                    value: 'integration_moreflo_admin',
                },
                {
                    label: <span>ClickUp: See</span>,
                    value: 'integration_clickup_see',
                },
                {
                    label: <span>ClickUp: Admin</span>,
                    value: 'integration_clickup_admin',
                },
            ],
        },
        {
            label: <span>Developer</span>,
            title: 'Developer',
            options: [
                {
                    label: <span>Developer info: See</span>,
                    value: 'developer_info_see',
                },
                {
                    label: <span>Developer info: Write</span>,
                    value: 'developer_info_write',
                },
                {
                    label: <span>Dev events: Create</span>,
                    value: 'developer_events_create',
                },
                {
                    label: <span>Dev events: Resolve</span>,
                    value: 'developer_events_resolve',
                },
                {
                    label: <span>Dev: Admin</span>,
                    value: 'developer_admin',
                },
            ],
        },
        {
            label: <span>Session</span>,
            title: 'Session',
            options: [
                {
                    label: <span>Session: See</span>,
                    value: 'session_see',
                },
                {
                    label: <span>Session: Create (!)</span>,
                    value: 'session_create',
                    disabled: !IsAdmin(grants)
                },
                {
                    label: <span>Session: Close</span>,
                    value: 'session_close',
                },
                {
                    label: <span>Session: Update</span>,
                    value: 'session_update',
                },
            ],
        },
        {
            label: <span>Access Token</span>,
            title: 'Access Token',
            options: [
                {
                    label: <span>Access Token: See</span>,
                    value: 'access_token_see',
                },
                {
                    label: <span>Access Token: Add (!)</span>,
                    value: 'access_token_add',
                    disabled: !CanCreateAccessTokens(grants)
                },
                {
                    label: <span>Access Token: Expire</span>,
                    value: 'access_token_expire',
                    disabled: !CanExpireAccessTokens(grants)
                },
                {
                    label: <span>Access Token: Revoke</span>,
                    value: 'access_token_revoke',
                    disabled: !CanRevokeAccessTokens(grants)
                },
            ],
        },
        {
            label: <span>Grants</span>,
            title: 'Grants',
            options: [
                {
                    label: <span>Grants: See</span>,
                    value: 'grants_see',
                },
                {
                    label: <span>Grants: Add (!)</span>,
                    value: 'grants_add',
                    disabled: !IsAdmin(grants)
                },
                {
                    label: <span>Grants: Remove</span>,
                    value: 'grants_remove',
                },
                {
                    label: <span>Grants: Admin (!)</span>,
                    value: 'grants_admin',
                    disabled: !IsAdmin(grants)
                },
            ],
        },
        {
            label: <span>Administration</span>,
            title: 'Administration',
            options: [
                {
                    label: <span>Administrator (!)</span>,
                    value: 'administrator',
                    disabled: !IsAdmin(grants)
                },
            ],
        },
    ]
}

export function IsAdmin(grants = {}) {
    return !!grants[Grants.Administration.Administrator];
}

export function CanSeeEvents(grants = {}) {
    return !!grants[Grants.Notifications.Events.See];
}

export function IsGrantsAdmin(grants = {}) {
    if (grants[Grants.Grants.Admin] === true) {
        return true
    }

    return false
}

export function IsDeveloper(grants = {}) {
    if (grants[Grants.Developer.Info.See] === true) {
        return true
    }

    return false
}

export function CanSeeLogs(grants = {}) {
    if (grants[Grants.Statistic.Logs.See] === true) {
        return true
    }

    return false
}

export function CanSeeProducts(grants = {}) {
    if (grants[Grants.Products.See] === true) {
        return true
    }

    return false
}

export function CanSeeCampaigns(grants = {}) {
    if (grants[Grants.Products.Campaigns.See] === true) {
        return true
    }

    return false
}

export function CanSeeStatistic(grants = {}) {
    if (grants[Grants.Statistic.See] === true) {
        return true
    }

    return false
}

export function CanSeeUsers(grants = {}) {
    if (grants[Grants.Users.See] === true) {
        return true
    }

    return false
}

export function CanEditUsers(grants = {}) {
    if (grants[Grants.Users.Edit] === true) {
        return true
    }

    return false
}

export function CanCreateUsers(grants = {}) {
    if (grants[Grants.Users.Add] === true) {
        return true
    }

    return false
}

export function CanSeeSlim4(grants = {}) {
    if (grants[Grants.Integrations.Slim4.See] === true) {
        return true
    }

    return false
}

export function CanEditSlim4(grants = {}) {
    if (grants[Grants.Integrations.Slim4.Admin] === true) {
        return true
    }

    return false
}

export function CanSeeMoreFlo(grants = {}) {
    if (grants[Grants.Integrations.MoreFlo.See] === true) {
        return true
    }

    return false
}

export function CanEditMoreFlo(grants = {}) {
    if (grants[Grants.Integrations.MoreFlo.Edit] === true) {
        return true
    }

    return false
}

export function CanSeeAccessTokens(grants = {}) {
    if (grants[Grants.AccessToken.See] === true) {
        return true
    }

    return false
}

export function CanCreateAccessTokens(grants = {}) {
    if (grants[Grants.AccessToken.Add] === true) {
        return true
    }

    return false
}

export function CanRevokeAccessTokens(grants = {}) {
    if (grants[Grants.AccessToken.Revoke] === true) {
        return true
    }

    return false
}

export function CanExpireAccessTokens(grants = {}) {
    if (grants[Grants.AccessToken.Expire] === true) {
        return true
    }

    return false
}