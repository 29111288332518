import React, { useEffect, useState } from 'react';
import { Col, Space, Row, Table, Form, Button, Select, Typography, Input } from "antd";
import { CaretRightOutlined, EyeOutlined, } from "@ant-design/icons";
import { NotificationError } from "../../components/notifications";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { BreadcrumbsSlim4CacheOrder } from "../../components/breadcrumbs/breadcrumbsSlim4";
import { GetLinkProductAskas } from "../../components/askas";
import { GetProductHref } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";
import { FormatShortUUID } from '../../components/formattingValues';

const { Paragraph } = Typography

export function Slim4CacheOrder() {
    const [form] = Form.useForm();
    const [lastBatches, setLatsBatches] = useState([])
    const [batchSelectedValue, setBatchSelectedValue] = useState('')
    const skuValue = Form.useWatch('sku', form);

    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const getBatchID = () => {
        let batchIDLocal = ""
        if (batchSelectedValue !== "" && batchSelectedValue !== undefined && batchSelectedValue !== null) {
            batchIDLocal = batchSelectedValue
        }

        return batchIDLocal
    }

    const getSKU = () => {
        let skuLocal = ""
        if (skuValue !== undefined && skuValue !== null && skuValue !== "") {
            skuLocal = skuValue
        }

        return skuLocal
    }

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/slim4/cache_records_order_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&batch_id=${getBatchID()}&code=${getSKU()}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                        let newLastRuns = [
                            { ID: "", Name: "all" }
                        ]
                        result.result.LastRuns.map((x) => (
                            newLastRuns.push({
                                ID: x.BatchNumber,
                                Name: <>{x.BatchNumber} (<TimeFromSeconds seconds={x.BatchTime.seconds * 1000} />)</>,
                            })
                        ))
                        setLatsBatches(newLastRuns)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    return (
        <>
            <Helmet>
                <title>Slim4 pipeline history || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsSlim4CacheOrder />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"History"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} >
                        <Form form={form} layout="vertical" autoComplete="off">
                            <Form.Item
                                name={`Batch ID`}
                                label={`Last runs`}
                            >
                                <Select
                                    onChange={(value) => { setBatchSelectedValue(value) }}
                                    options={lastBatches}
                                    loading={loading}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item name="sku" label="Item SKU">
                                <Input />
                            </Form.Item>
                            <Button type="primary" onClick={fetchData}>
                                Get history <CaretRightOutlined />
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.code}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            scroll={{
                                y: 500,
                                x: 4000,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}



const columns = [
    {
        title: 'Item SKU',
        dataIndex: 'Code',
        key: 'Code',
        fixed: 'left',
        render: (_, record) => (
            <>
                <Space wrap>
                    <Paragraph copyable={{ text: record.Code }}>{record.Code}</Paragraph>
                    <Button type="primary" href={GetLinkProductAskas(record.Code)} target={"_blank"}
                        rel={"noreferrer"}>
                        Å
                    </Button>
                    <Button type="primary" href={GetProductHref(record.Code)} target={"_blank"}
                        rel={"noreferrer"}>
                        <EyeOutlined />
                    </Button>
                </Space>
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'BatchNumber',
        key: 'BatchNumber',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.BatchNumber }}>{FormatShortUUID(record.BatchNumber)}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'BatchTime',
        key: 'BatchTime',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.BatchTime.seconds * 1000} /></>
        ),
    },
    {
        title: 'BuyingPrice',
        dataIndex: 'BuyingPrice',
        key: 'BuyingPrice',
    },
    {
        title: 'ControlID',
        dataIndex: 'ControlID',
        key: 'ControlID',
    },
    {
        title: 'DeliveryDate',
        dataIndex: 'DeliveryDate',
        key: 'DeliveryDate',
    },
    {
        title: 'LineNumber',
        dataIndex: 'LineNumber',
        key: 'LineNumber',
    },
    {
        title: 'OpenQuantity',
        dataIndex: 'OpenQuantity',
        key: 'OpenQuantity',
    },
    {
        title: 'OrderTypeNumber',
        dataIndex: 'OrderTypeNumber',
        key: 'OrderTypeNumber',
    },
    {
        title: 'OriginalQuantity',
        dataIndex: 'OriginalQuantity',
        key: 'OriginalQuantity',
    },
    {
        title: 'PoComment',
        dataIndex: 'PoComment',
        key: 'PoComment',
    },
    {
        title: 'PoNumber',
        dataIndex: 'PoNumber',
        key: 'PoNumber',
    },
    {
        title: 'SupplierNumber',
        dataIndex: 'SupplierNumber',
        key: 'SupplierNumber',
    },
    {
        title: 'SupplierName',
        dataIndex: 'SupplierName',
        key: 'SupplierName',
    },
    {
        title: 'Warehouse',
        dataIndex: 'Warehouse',
        key: 'Warehouse',
    },
];