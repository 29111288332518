import React from 'react';
import { Col, Row } from "antd";
import { BreadcrumbsProducts } from "../../components/breadcrumbs/breadcrumbsProducts";
import { Helmet } from "react-helmet";

export function Products() {
    return (
        <>
            <Helmet>
                <title>Products || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProducts />
                    </Col>
                </Row>
            </div>
        </>
    )
}