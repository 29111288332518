import React from 'react';
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Spinner, SpinProps } from "../../components/spinners";
import { ErrorRow } from "../../components/errorBlock";
import { useParams } from "react-router-dom";
import { Card, Descriptions, Table } from "antd";
import { Col, Row } from 'antd';
import { GetTagFromValue } from "../../components/formattingValues";
import { CanSeeMoreFlo } from "../../components/backend/backendRoles";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
    BreadcrumbsProductsProductMoreFlo,
} from "../../components/breadcrumbs/breadcrumbsProducts";
import { InlineMenu } from "../../components/menus/inline";
import { GetProductPageMenuItems } from "../../components/menus/itemsProductPage";
import {
    Groups,
    AccountCodes, GetLinkProductMoreFloAPI,
} from "../../components/moreflo";
import { ProductLinkInline } from "../../components/product";
import { HandleTableChange } from "../../components/tables/tableFunctions";
import { Helmet } from "react-helmet";
import { Tag, Typography } from "antd";
import { MoreFloGroups } from "../../components/backend/backend";
import { TimeFromSecondsSafe } from '../../components/dateAndTime';

const { Paragraph } = Typography

export function ProductMoreFlo(props) {
    const { itemSKU } = useParams();
    const [apiError, setApiError] = useState("");
    const [apiFetching, setApiFetching] = useState(true);
    const [apiData, setApiData] = useState({
        "IsActive": false,
        "IsWebshopArticle": false,
        "IsBookable": false,
        "IsOpenArticle": false,
        "IsQuickButton": false
    });
    const [historyFetching, setHistoryFetching] = useState(true);
    const [historyError, setHistoryError] = useState("");
    const [historyData, setHistoryData] = useState([]);
    const [historyTableParams, setHistoryTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChange = () => (
        pagination,
        filters,
        sorter
    ) => {
        HandleTableChange(
            pagination,
            filters,
            sorter,
            historyTableParams,
            setHistoryTableParams,
            setHistoryData,
        )
    }

    // eslint-disable-next-line
    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/integrations/more_flo/get_product_from_api?sku=${itemSKU}`,
            setApiFetching,
            setApiError,
            setApiData
        ).then()
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/integrations/more_flo/get_product_from_cache?page=${historyTableParams.pagination.current}&size=${historyTableParams.pagination.pageSize}&sku=${itemSKU}`,
            setHistoryFetching,
            setHistoryError,
            null,
        )
            .then(
                (result) => {
                    const parsedData = result.Records.map(record => ({
                        ...record,
                        ...JSON.parse(record.ItemData)
                    }));
                    setHistoryData(parsedData);
                }
            )
        // eslint-disable-next-line
    }, [historyTableParams.pagination.current, historyTableParams.pagination.pageSize]);

    useEffect(() => {
        setHistoryTableParams({
            ...historyTableParams,
            pagination: {
                ...historyTableParams.pagination,
                total: historyData.Total,
            },
        });
        // eslint-disable-next-line
    }, [historyData]);


    const menuItems = GetProductPageMenuItems(itemSKU)
    menuItems.push(
        {
            label: CanSeeMoreFlo(props.token.grants) ? <a
                href={GetLinkProductMoreFloAPI(itemSKU)}
                target={"_blank"} rel="noreferrer">Check in API</a> : "Check in API",
            icon: <ArrowRightOutlined />,
            disabled: !CanSeeMoreFlo(props.token.grants)
        },
    )

    return (
        <>
            <Helmet>
                <title>Product data (MoreFlo) || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsProductMoreFlo sku={itemSKU} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <InlineMenu items={menuItems} />
                    </Col>
                </Row>
            </div>

            <ErrorRow err={apiError} prefix={"API"} />
            {apiFetching === true ? <Spinner tip={""} /> : <></>}
            {apiError === "" && !apiFetching ? (
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Card
                                title={itemSKU + " More Flo API"}
                                bordered={false}
                                style={{ backgroundColor: "transparent", borderRadius: 0, borderColor: "transparent" }}
                            >
                                <HTMLApiData data={apiData} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : <></>}

            <ErrorRow err={historyError} prefix={"Cache"} />
            {historyError === "" ? (
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Table
                                columns={ColumnsMoreFloHistory}
                                rowKey={(record) => record.BatchNumber} // Ensure unique key
                                dataSource={historyData}
                                pagination={historyTableParams.pagination}
                                loading={historyFetching ? SpinProps : false}
                                size={"small"}
                                style={{ borderRadius: 0 }}
                                onChange={handleTableChange}
                                scroll={{
                                    y: 500,
                                    x: 4000,
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            ) : <></>}
        </>
    )
}

function HTMLApiData(props) {
    let apiData
    apiData = props.data
    return (
        <Descriptions>
            <Descriptions.Item
                label="ArticleNumber"><ProductLinkInline sku={apiData.ArticleNumber} /></Descriptions.Item>
            <Descriptions.Item
                label="ArticleName">{apiData.ArticleName}</Descriptions.Item>
            <Descriptions.Item
                label="AccountCodeId">{AccountCodes[apiData.AccountCodeId] ? <>
                    <a href={`https://api.moreflo.com/v2/accountcodes/${apiData.AccountCodeId}`}
                        target={"_blank"}
                        rel="noreferrer">{AccountCodes[apiData.AccountCodeId]}</a></> : <>{apiData.AccountCodeId}</>}</Descriptions.Item>
            <Descriptions.Item
                label="ArticleInfoUpdateTime">{apiData.ArticleInfoUpdateTime}</Descriptions.Item>
            <Descriptions.Item
                label="ArticleType">{apiData.ArticleType}</Descriptions.Item>
            <Descriptions.Item
                label="Barcode">{apiData.Barcode}</Descriptions.Item>
            <Descriptions.Item
                label="BarcodeAlternatives">{apiData.BarcodeAlternatives}</Descriptions.Item>
            <Descriptions.Item
                label="GroupId">{Groups[apiData.GroupId] ? <>
                    <a href={`https://api.moreflo.com/v2/groups/${apiData.GroupId}`}
                        target={"_blank"}
                        rel="noreferrer">{Groups[apiData.GroupId]}</a></> : <>{apiData.GroupId}</>}</Descriptions.Item>
            <Descriptions.Item
                label="InputPrice">{apiData.InputPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Is Active">{GetTagFromValue(apiData.IsActive)}</Descriptions.Item>
            <Descriptions.Item
                label="Is Bookable">{apiData.IsBookable ? apiData.IsBookable.toString() : 'false'}</Descriptions.Item>
            <Descriptions.Item
                label="Is Open Article">{apiData.IsOpenArticle ? apiData.IsOpenArticle.toString() : 'false'}</Descriptions.Item>
            <Descriptions.Item
                label="Is Quick Button">{apiData.IsQuickButton ? apiData.IsQuickButton.toString() : 'false'}</Descriptions.Item>
            <Descriptions.Item
                label="Is Webshop Article">{apiData.IsWebshopArticle ? apiData.IsWebshopArticle.toString() : 'false'}</Descriptions.Item>
            <Descriptions.Item
                label="Quantity">{apiData.Quantity}</Descriptions.Item>
            <Descriptions.Item
                label="Quantity Updated">{apiData.QuantityUpdatedTime}</Descriptions.Item>
            <Descriptions.Item
                label="Recommended Price">{apiData.RecommendedPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Retail Price">{apiData.RetailPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Unit">{apiData.Unit}</Descriptions.Item>
        </Descriptions>
    )
}

const ColumnsMoreFloHistory = [
    {
        title: 'Item SKU',
        dataIndex: 'ArticleNumber',
        key: 'ArticleNumber',
        fixed: 'left',
        render: (_, record) => (
            <>
                <ProductLinkInline sku={record.SKU} />
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'BatchNumber',
        key: 'BatchNumber',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.BatchNumber }}>{record.BatchNumber}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'BatchTime',
        key: 'BatchTime',
        render: (_, record) => (
            <><TimeFromSecondsSafe format="YYYY.MM.DD HH:mm:ss" time={record.BatchTime} /></>
        ),
    },
    {
        title: 'Article Name',
        dataIndex: 'ArticleName',
        key: 'ArticleName',
    },
    {
        title: 'Updated',
        dataIndex: 'Updated',
        key: 'Updated',
        render: (_, record) => (
            <><TimeFromSecondsSafe format="YYYY.MM.DD HH:mm:ss" time={record.UpdatedAt} /></>
        ),
    },
    {
        title: 'Barcodes',
        dataIndex: 'Barcodes',
        key: 'Barcodes',
        render: (_, record) => (
            <>{record.Barcode + "," + JSON.stringify(record.BarcodeAlternatives)}</>
        ),
    },
    {
        title: 'Retail Price',
        dataIndex: 'RetailPrice',
        key: 'RetailPrice',
    },
    {
        title: 'Is Active',
        dataIndex: 'IsActive',
        key: 'IsActive',
        render: (_, record) => (
            <>{record.IsActive ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Group ID',
        dataIndex: 'GroupId',
        key: 'GroupId',
        render: (_, record) => (
            <>{MoreFloGroups[record.GroupId]}</>
        ),
    },
    {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key: 'Quantity',
    },
    {
        title: 'Quantity Updated',
        dataIndex: 'QuantityUpdatedTime',
        key: 'QuantityUpdatedTime',
    },
    {
        title: 'Account Code ID',
        dataIndex: 'AccountCodeId',
        key: 'AccountCodeId',
    },
    {
        title: 'Article Info Updated',
        dataIndex: 'ArticleInfoUpdateTime',
        key: 'ArticleInfoUpdateTime',
    },
    {
        title: 'Created',
        dataIndex: 'Created',
        key: 'Created',
        render: (_, record) => (
            <>{JSON.stringify(record.CreatedAt.Time)}</>
        ),
    },
    {
        title: 'Input Price',
        dataIndex: 'InputPrice',
        key: 'InputPrice',
    },
    {
        title: 'Recommended Price',
        dataIndex: 'RecommendedPrice',
        key: 'RecommendedPrice',
    },
    {
        title: 'Unit',
        dataIndex: 'Unit',
        key: 'Unit',
    },
    {
        title: 'Is Bookable',
        dataIndex: 'IsBookable',
        key: 'IsBookable',
        render: (_, record) => (
            <>{record.IsBookable ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Open Article',
        dataIndex: 'IsOpenArticle',
        key: 'IsOpenArticle',
        render: (_, record) => (
            <>{record.IsOpenArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Quick Button',
        dataIndex: 'IsQuickButton',
        key: 'IsQuickButton',
        render: (_, record) => (
            <>{record.IsQuickButton ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Stock Item',
        dataIndex: 'IsStockItem',
        key: 'IsStockItem',
        render: (_, record) => (
            <>{record.IsStockItem ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Webshop Article',
        dataIndex: 'IsWebshopArticle',
        key: 'IsWebshopArticle',
        render: (_, record) => (
            <>{record.IsWebshopArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
];