import React, { useEffect, useState, useRef } from 'react';
import { Col, Space, Row, Table, Tag, Form, Tour, Button, Select, Typography, Input } from "antd";
import { CaretRightOutlined, EyeOutlined, CloudDownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { NotificationError } from "../../components/notifications";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { BreadcrumbsSlim4CacheProduct } from "../../components/breadcrumbs/breadcrumbsSlim4";
import { GetLinkProductAskas } from "../../components/askas";
import { GetProductHref } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";
import { FormatShortUUID } from '../../components/formattingValues';

const { Paragraph } = Typography

export function Slim4CacheProduct() {
    const refPickRun = useRef(null);
    const refDownload = useRef(null);
    const refGetData = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Pick run',
            description: 'You can get data for all runs/brands/UD1 values or pick specific one from the list.',
            target: () => refPickRun.current,
        },
        {
            title: 'Download CSV',
            description: <>You can download in CSV, but only for one specific run at once. It will take some time. Pick any record in dropdown and the button will become active. You <strong>don`t</strong> need to get data in browser before that: simply pick a record and press download.</>,
            target: () => refDownload.current,
        },
        {
            title: 'Get data',
            description: 'Press "Get history" to get product data records on browser tab.',
            target: () => refGetData.current,
        },
        {
            title: 'Table',
            description: <><strong>Logistics</strong>, <strong>Article Code Master</strong> & <strong>Stock</strong> fields have to be <Tag color={'lime'}>YES</Tag> to indicate that product was uploaded. If any of these parameters set to <Tag color={'volcano'}>NO</Tag>, this means its data was not sent to Slim4 because some fields in this category were missing.</>,
        },
    ];

    const [form] = Form.useForm();
    const [lastBatches, setLatsBatches] = useState([])
    const [batchSelectedValue, setBatchSelectedValue] = useState('')
    const skuValue = Form.useWatch('sku', form);
    const [brandSelectedValue, setBrandSelectedValue] = useState('')
    const [brands, setBrands] = useState([])
    const [udOnes, setUDOnes] = useState([])
    const [udOneSelectedValue, setUDOneSelectedValue] = useState('')

    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const getBatchID = () => {
        let batchIDLocal = ""
        if (batchSelectedValue !== "" && batchSelectedValue !== undefined && batchSelectedValue !== null) {
            batchIDLocal = batchSelectedValue
        }

        return batchIDLocal
    }

    const getSKU = () => {
        let skuLocal = ""
        if (skuValue !== undefined && skuValue !== null && skuValue !== "") {
            skuLocal = skuValue
        }

        return skuLocal
    }

    const getBrandID = () => {
        let brandIDLocal = ""
        if (brandSelectedValue !== "" && brandSelectedValue !== undefined) {
            brandIDLocal = brandSelectedValue
        }

        return brandIDLocal
    }

    const getUDOne = () => {
        let udOneLocal = ""
        if (udOneSelectedValue !== "" && udOneSelectedValue !== undefined) {
            udOneLocal = udOneSelectedValue
        }

        return udOneLocal
    }

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/slim4/cache_records_product_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&batch_id=${getBatchID()}&sku=${getSKU()}&brand_id=${getBrandID()}&ud1=${getUDOne()}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                        let newLastRuns = [
                            { ID: "", Name: "all" }
                        ]
                        result.result.LastRuns.map((x) => (
                            newLastRuns.push({
                                ID: x.BatchNumber,
                                Name: <>{x.BatchNumber} (<TimeFromSeconds seconds={x.BatchTime.seconds * 1000} />)</>,
                            })
                        ))
                        setLatsBatches(newLastRuns)

                        let newUDOnes = [
                        ]
                        result.result.UD1Filter.map((x) => (
                            newUDOnes.push({
                                ID: x.UD1,
                                Name: x.UD1,
                            })
                        ))
                        setUDOnes(newUDOnes)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const [brandsErr, setBrandsErr] = useState('');
    const [brandsLoading, setBrandsLoading] = useState(true);

    const fetchBrands = (page, size) => {
        setBrandsLoading(true);
        GetRequest(getBackendLink() + `/api/v1/products/brands?page=${page}&size=${size}&ids=''`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setBrandsErr(result.error)
                    } else {
                        setBrandsErr("")

                        let newBrands = [
                            { ID: "", Name: "all" }
                        ]

                        result.result.Records.map((x) => (
                            newBrands.push({
                                ID: x.ID,
                                Name: <>{x.Name} ({x.ID})</>,
                            })
                        ))
                        setBrands(newBrands)
                    }
                    setBrandsLoading(false);
                },
                (error) => {
                    setBrandsLoading(false);
                    NotificationError('Brands', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchBrands(0, 0)
    }, []);

    return (
        <>
            <Helmet>
                <title>Slim4 pipeline history || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsSlim4CacheProduct />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refDownload}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                Take a Tour <QuestionCircleOutlined />
                            </Button>
                            <Button href={getBackendLink() + `/api/v1/integrations/slim4/cache_records_product_get_csv?batch_id=${getBatchID()}&sku=${getSKU()}&brand_id=${getBrandID()}&ud1=${getUDOne()}`}
                                type="primary"
                                disabled={getBatchID() === "" && getBrandID() === "" && getSKU() === "" && getUDOne() === ""}
                                style={{ backgroundColor: "green" }}
                            >
                                Download CSV <CloudDownloadOutlined />
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"History"} />
                <ErrorRow err={brandsErr} prefix={"Brands"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refPickRun} >
                        <Form form={form} layout="vertical" autoComplete="off">
                            <Form.Item
                                name={`Batch ID`}
                                label={`Last runs`}
                            >
                                <Select
                                    onChange={(value) => { setBatchSelectedValue(value) }}
                                    options={lastBatches}
                                    loading={loading}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={`Brand`}
                            >
                                <Select
                                    onChange={(value) => { setBrandSelectedValue(value) }}
                                    options={brands}
                                    loading={brandsLoading}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={`UD1 Value`}
                            >
                                <Select
                                    onChange={(value) => { setUDOneSelectedValue(value) }}
                                    options={udOnes}
                                    loading={loading}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item name="sku" label="Item SKU">
                                <Input />
                            </Form.Item>
                            <Button type="primary" onClick={fetchData} ref={refGetData}>
                                Get history <CaretRightOutlined />
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.code}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            scroll={{
                                y: 500,
                                x: 4000,
                            }}
                        />
                    </Col>
                </Row>
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    )
}

const columns = [
    {
        title: 'Item SKU',
        dataIndex: 'Code',
        key: 'Code',
        fixed: 'left',
        render: (_, record) => (
            <>
                <Space wrap>
                    <Paragraph copyable={{ text: record.Code }}>{record.Code}</Paragraph>
                    <Button type="primary" href={GetLinkProductAskas(record.Code)} target={"_blank"}
                        rel={"noreferrer"}>
                        Å
                    </Button>
                    <Button type="primary" href={GetProductHref(record.Code)} target={"_blank"}
                        rel={"noreferrer"}>
                        <EyeOutlined />
                    </Button>
                </Space>
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'BatchNumber',
        key: 'BatchNumber',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.BatchNumber }}>{FormatShortUUID(record.BatchNumber)}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'BatchTime',
        key: 'BatchTime',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.BatchTime.seconds * 1000} /></>
        ),
    },
    {
        title: 'Logistics',
        dataIndex: 'HasLogistics',
        key: 'HasLogistics',
        render: (_, record) => (
            <>{record.HasLogistics === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Article Code Master',
        dataIndex: 'HasArticleCodeMaster',
        key: 'HasArticleCodeMaster',
        render: (_, record) => (
            <>{record.HasArticleCodeMaster === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Stock',
        dataIndex: 'HasStock',
        key: 'HasStock',
        render: (_, record) => (
            <>{record.HasStock === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        key: 'cancelled',
        render: (_, record) => (
            <>{record.cancelled ? <Tag color={'volcano'}>YES</Tag> :
                <Tag color={'lime'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Activity in 2 years',
        dataIndex: 'HasActivityIn2years',
        key: 'HasActivityIn2years',
        render: (_, record) => (
            <>{record.HasActivityIn2years !== "" ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Brand ID',
        dataIndex: 'BrandID',
        key: 'BrandID',
    },
    {
        title: 'Buying Price',
        dataIndex: 'BuyingPrice',
        key: 'BuyingPrice',
        render: (_, record) => (
            <>{record.BuyingPrice}</>
        ),
    },
    {
        title: 'DisposableQuantity',
        dataIndex: 'DisposableQuantity',
        key: 'DisposableQuantity',
    },
    {
        title: 'IOQ',
        dataIndex: 'IOQ',
        key: 'IOQ',
    },
    {
        title: 'ISQ',
        dataIndex: 'ISQ',
        key: 'ISQ',
    },
    {
        title: 'MOQ',
        dataIndex: 'MOQ',
        key: 'MOQ',
    },
    {
        title: 'MSQ',
        dataIndex: 'MSQ',
        key: 'MSQ',
    },
    {
        title: 'LeadTime',
        dataIndex: 'LeadTime',
        key: 'LeadTime',
    },
    {
        title: 'StockedItem',
        dataIndex: 'StockedItem',
        key: 'StockedItem',
    },
    {
        title: 'SupplierName',
        dataIndex: 'SupplierName',
        key: 'SupplierName',
    },
    {
        title: 'SupplierNumber',
        dataIndex: 'SupplierNumber',
        key: 'SupplierNumber',
    },
    {
        title: 'AUDField3',
        dataIndex: 'AUDField3',
        key: 'AUDField3',
    },
    {
        title: 'BarCode',
        dataIndex: 'BarCode',
        key: 'BarCode',
    },
    {
        title: 'CreationDate',
        dataIndex: 'CreationDate',
        key: 'CreationDate',
    },
    {
        title: 'Criterium1',
        dataIndex: 'Criterium1',
        key: 'Criterium1',
    },
    {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
    },
    {
        title: 'GroupCode1',
        dataIndex: 'GroupCode1',
        key: 'GroupCode1',
    },
    {
        title: 'GroupCode2',
        dataIndex: 'GroupCode2',
        key: 'GroupCode2',
    },
    {
        title: 'GroupCode3',
        dataIndex: 'GroupCode3',
        key: 'GroupCode3',
    },
    {
        title: 'GroupCode4',
        dataIndex: 'GroupCode4',
        key: 'GroupCode4',
    },
    {
        title: 'SalesPrice',
        dataIndex: 'SalesPrice',
        key: 'SalesPrice',
    },
    {
        title: 'StockIdOutOfStock',
        dataIndex: 'StockIdOutOfStock',
        key: 'StockIdOutOfStock',
    },
    {
        title: 'UD1',
        dataIndex: 'UD1',
        key: 'UD1',
    },
    {
        title: 'UnitPrice',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
    },
    {
        title: 'Warehouse',
        dataIndex: 'Warehouse',
        key: 'Warehouse',
    },
];
